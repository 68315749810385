import React from "react";
import { Link } from "react-router-dom";
import { Drawer, Menu, Image, Row, Col } from "antd";
import { Navbar } from "react-bootstrap";
import location from "../../images/colorLocation.png";

import { RightOutlined } from "@ant-design/icons";
import cart from "./../../images/Iconly-Light-Buy.png";
import logo from "./../../images/logo2.jpeg";
import "./header.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavExpanded: false,
      visible: false,
      placement: "left",
      storeName: "",
      streetName: "",
      landingInfo: {},
    };
  }
  componentDidMount() {
    const storeDetails = JSON.parse(localStorage.getItem("storeDetails"));
    const landingInfo = JSON.parse(localStorage.getItem("imagesForMessages"));
    const storeName = JSON.parse(storeDetails.store_name).cn;
    const streetName = storeDetails.street;
    this.setState({
      storeName: storeName,
      streetName: streetName,
      landingInfo: landingInfo,
    });
    document.addEventListener("click", this.handleClick, false);
  }
  setIsNavExpanded = (isNavExpanded) => {
    this.setState({ isNavExpanded: isNavExpanded });
  };
  closeMenu = () => {
    this.setState({ isNavExpanded: false });
  };

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      // if clicked inside menu do something
    } else {
      // If clicked outside menu, close the navbar.
      this.setState({ isNavExpanded: false });
    }
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };

  onHomePage = (e) => {
    window.location = "/#/";
  };
  onTerms = (e) => {
    window.location = "/#/termsAndConditions";
  };
  onRefund = (e) => {
    window.location = "/#/refundAndCancel";
  };
  onPrivacy = (e) => {
    window.location = "/#/privacy";
  };
  onContact = (e) => {
    window.location = "/#/contactPage";
  };

  render() {
    const { placement, visible, landingInfo, streetName } = this.state;
    const responsiveDesignText = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };

    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };
    const basket = JSON.parse(localStorage.getItem("basket"));
    let itemsQuantity = 0.0;
    for (let i = 0; i < basket.length; i += 1) {
      itemsQuantity += basket[i].Quantity;
    }
    return (
      <div ref={(node) => (this.node = node)}>
        <Navbar
          onToggle={this.setIsNavExpanded}
          expanded={this.state.isNavExpanded}
          color="light"
          expand="lg"
          className="navbar-light osahan-nav shadow-sm"
          style={{ textAlign: "center" }}
        >
          <div>
            <Row>
              <Col {...responsiveDesignText} style={{ textAlign: "left" }}>
                <Navbar.Toggle onClick={this.showDrawer} />
              </Col>
              <Col
                {...responsiveDesignText}
                style={{
                  marginBottom: "-10%",
                  marginTop: "-5%",
                  marginLeft: "-5%",
                }}
              >
                <img
                  src={landingInfo.Logo}
                  alt=""
                  style={{
                    borderRadius: "0px",
                    width: "150%",
                    height: "75%",
                  }}
                />
              </Col>
              <Col
                {...responsiveDesignText}
                style={{
                  marginLeft: "2%",
                  marginTop: "3%",
                  textAlign: "right",
                }}
              >
                <Link to="../viewCart/insideCart/index.js">
                  <img
                    src={cart}
                    alt="cart"
                    style={{
                      fontWeight: "700",
                    }}
                    onClick={this.onCartClick}
                  />
                  <button className="notifications" width="20px">
                    {itemsQuantity}
                  </button>
                </Link>
              </Col>
            </Row>
            <div style={{ marginBottom: "-8px" }}>
              <Row>
                <Col
                  {...responsiveDesignForColumn}
                  style={{ textAlign: "center" }}
                >
                  <p
                    style={{
                      fontSize: "11px",
                      color: "black",
                      marginBottom: "-1px",
                      marginTop: "9px",
                    }}
                  >
                    <img
                      src={location}
                      alt=""
                      style={{
                        borderRadius: "0px",
                        width: "2.2%",
                      }}
                    />{" "}
                    {streetName}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Navbar>
        <Drawer
          placement={placement}
          closable={false}
          onClose={this.onClose}
          visible={visible}
          key={placement}
        >
          <Menu
            // defaultSelectedKeys={["1"]}
            // onClick={this.handleDashboardMenuClick}
            mode="inline"
            theme="light"
            inlineCollapsed={this.state.collapsed}
          >
            <Menu.Item
              key="1"
              onClick={this.onHomePage}
              style={{ paddingBottom: "20px" }}
            >
              <Image
                src={logo}
                alt=""
                style={{ marginTop: "0px", width: "12%" }}
              />
            </Menu.Item>
            <Menu.Item key="1" onClick={this.onHomePage}>
              Home <span className="sr-only"></span>
              <RightOutlined
                onClick={this.onBackToCart}
                style={{ fontSize: "12px", paddingLeft: "137px" }}
              />
            </Menu.Item>
            <Menu.Item key="2" onClick={this.onTerms}>
              Terms and Conditions <span className="sr-only">(current)</span>
              <RightOutlined
                onClick={this.onBackToCart}
                style={{ fontSize: "12px", paddingLeft: "40px" }}
              />
            </Menu.Item>
            <Menu.Item key="3" onClick={this.onRefund}>
              Refund / Cancelation Policies{" "}
              <span className="sr-only">(current)</span>
              <RightOutlined
                onClick={this.onBackToCart}
                style={{ fontSize: "12px", paddingLeft: "40px" }}
              />
            </Menu.Item>
            <Menu.Item key="4" onClick={this.onPrivacy}>
              Privacy Policies <span className="sr-only">(current)</span>
              <RightOutlined
                onClick={this.onBackToCart}
                style={{ fontSize: "12px", paddingLeft: "76px" }}
              />
            </Menu.Item>
            <Menu.Item key="6" onClick={this.onContact}>
              Contact Us <span className="sr-only">(current)</span>
              <RightOutlined
                onClick={this.onBackToCart}
                style={{ fontSize: "12px", paddingLeft: "105px" }}
              />
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
    );
  }
}

export default Header;

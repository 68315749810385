import React from "react";

import "react-phone-input-2/lib/style.css";
import { Row, Col, Card, Button } from "antd";
import detele from "../../../images/Icon_delete.png";
import emptyBasketr from "../../../images/emptyBasket.png";
import "./index.css";
import "antd/dist/antd.css";
import "../../../customCss/style.css";
import { LeftOutlined } from "@ant-design/icons";

class InsideCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      basket: [],
      product: {},
      total: 0.0,
      itemsValue: 0.0,
      combinationIds: "",
      selectedAddons: [],
    };
  }

  componentDidMount = () => {
    const basket = JSON.parse(localStorage.getItem("basket"));
    const modifiedItemsTotal = JSON.parse(
      localStorage.getItem("modifiedItemsTotal")
    );
    const combinationId = localStorage.getItem("combinationId");

    const total = parseFloat(localStorage.getItem("total")).toFixed(2);
    const itemsValue = parseFloat(localStorage.getItem("itemsQuantity"));
    this.setState({
      basket: basket,
      total: total,
      itemsValue: itemsValue,
      combinationIds: combinationId,
      modifiedItemsTotal: modifiedItemsTotal,
    });
  };

  onProceedPay = () => {
    const { history } = this.props;

    history.push("../salesType/index.js");
  };

  onBackToMenu = () => {
    const { history } = this.props;
    history.push("../../thanks");
  };

  addProductToBasket = (product, Quantity) => {
    const { basket } = this.state;

    const index = basket.findIndex(
      (basketItem) =>
        basketItem.combinationId === product.combinationId &&
        parseFloat(basketItem.Quantity) >= 0
    );

    if (index >= 0) {
      basket[index].Quantity = parseFloat(basket[index].Quantity) + Quantity;
      if (basket[index].Quantity <= 0) {
        basket.splice(index, 1);
      }
    } else {
      basket.push(product);
    }

    let total = 0.0;
    let itemsValues = 0.0;
    for (let i = 0; i < basket.length; i += 1) {
      if (basket[i].listPrice) {
        total +=
          parseFloat(basket[i].listPrice).toFixed(2) * basket[i].Quantity;
      } else {
        total += parseFloat(basket[i].Price).toFixed(2) * basket[i].Quantity;
      }

      itemsValues += basket[i].Quantity;
    }

    this.setState({ basket: basket, total: total, itemsValue: itemsValues });

    localStorage.setItem("basket", JSON.stringify(basket));
    localStorage.setItem("total", parseFloat(total).toFixed(2));
    localStorage.setItem("itemsQuantity", itemsValues);
  };

  deleteProductFromBasket = (selectedProductId) => {
    const { basket } = this.state;
    const index = basket.findIndex(
      (basketItem) => basketItem.Prod_ID === selectedProductId
    );
    if (index >= 0) {
      basket.splice(index, 1);
    }
    let total = 0.0;
    let itemsValues = 0.0;
    for (let i = 0; i < basket.length; i += 1) {
      total += parseFloat(basket[i].Price).toFixed(2) * basket[i].Quantity;
      itemsValues += basket[i].Quantity;
    }

    this.setState({ basket: basket, total: total, itemsValue: itemsValues });

    localStorage.setItem("basket", JSON.stringify(basket));
    localStorage.setItem("total", parseFloat(total).toFixed(2));
    localStorage.setItem("itemsQuantity", itemsValues);
  };

  render() {
    const { basket } = this.state;
    const values = basket.length;
    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };
    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };
    const responsiveDesignButtons = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 12,
      sm: 12,
      md: 12,
    };

    const responsiveDesignText = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };
    const responsiveDesignDish = {
      xxl: 6,
      xl: 6,
      lg: 6,
      xs: 6,
      sm: 6,
      md: 6,
    };

    const intFrameHeight = window.innerHeight;
    const intFrameHeightFor = window.innerHeight / 1.55;
    const intViewportWidth = window.innerWidth;
    return (
      <div
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",

          backgroundColor: "#fff",
          height: `${intFrameHeight}px`,
        }}
        id="example"
      >
        {values !== 0 ? (
          <Row>
            <Col span={24}>
              <Card
                style={{
                  fontWeight: "regular",
                  fontSize: "25px",
                }}
              >
                <Row
                  style={{
                    margin: "0px",
                    paddingBottom: "3px",
                  }}
                >
                  <Col
                    {...responsiveDesignForImage}
                    style={{ paddingRight: "3px" }}
                  >
                    <LeftOutlined
                      onClick={this.onBackToMenu}
                      style={{ fontSize: "20px" }}
                    />
                  </Col>
                  <Col
                    {...responsiveDesignForItemData}
                    style={{
                      textAlign: "center",
                      paddingRight: "35px",
                      paddingTop: "3px",
                    }}
                  >
                    Your Cart
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ) : (
          <div style={{ display: "none" }}></div>
        )}

        <div
          style={{ maxHeight: `${intFrameHeightFor}px`, overflowY: "scroll" }}
        >
          {basket.map((data) => {
            return (
              <Row>
                <Col {...responsiveDesignForColumn} style={{ padding: "8px" }}>
                  <Card
                    style={{
                      borderRadius: "1px",
                      // margin: "-10px",
                      padding: "0px",
                      boxShadow: " 0px 2px 6px -3px",
                    }}
                    actions={[
                      <Row>
                        <Col
                          {...responsiveDesignText}
                          style={{ paddingLeft: "18px" }}
                        >
                          <Row>
                            <Col {...responsiveDesignText}>
                              <i
                                onClick={() =>
                                  this.addProductToBasket(data, -1)
                                }
                                className="fa fa-minus-circle fa-2x"
                                aria-hidden="true"
                              ></i>
                            </Col>

                            <Col {...responsiveDesignText}>
                              <p style={{ paddingTop: "5px" }}>
                                {data.Quantity}
                              </p>
                            </Col>
                            <Col {...responsiveDesignText}>
                              <i
                                onClick={() => this.addProductToBasket(data, 1)}
                                className="fa fa-plus-circle fa-2x"
                                aria-hidden="true"
                              ></i>
                            </Col>
                          </Row>
                        </Col>
                        <Col {...responsiveDesignButtons} />
                        <Col {...responsiveDesignForImage}>
                          <Button
                            onClick={() =>
                              this.deleteProductFromBasket(data.Prod_ID)
                            }
                            style={{ border: "0px solid white" }}
                          >
                            <img
                              style={{
                                fontWeight: "700",
                              }}
                              src={detele}
                              alt=""
                            />
                          </Button>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <Row>
                      <Col {...responsiveDesignDish} value={data.combinationId}>
                        <img
                          src={data.Image_Url}
                          alt=""
                          width="60"
                          height="55"
                          key={data.Prod_ID}
                          style={{ borderRadius: "0px" }}
                        />
                      </Col>

                      <Col {...responsiveDesignButtons}>
                        <Row>
                          <Col>
                            <b style={{ color: "#512C1D" }}>{data.Name}</b>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        {...responsiveDesignForImage}
                        style={{ float: "right" }}
                      >
                        <Row>
                          <Col lg={24}>
                            <b style={{ float: "right", color: "#512C1D" }}>
                              $
                              {data.Customizable === 0 || data.listPrice === 0.0
                                ? parseFloat(data.Price).toFixed(2)
                                : parseFloat(data.listPrice).toFixed(2)}
                            </b>
                          </Col>
                        </Row>
                      </Col>
                      <Row style={{ paddingLeft: "80px" }}>
                        <Col {...responsiveDesignForColumn}>
                          {data.selectedAddons ? (
                            <div
                              style={{
                                display: "flex",
                                maxWidth: "240px",
                                overflowX: "scroll",
                              }}
                            >
                              {data.selectedAddons.map((value) => {
                                return (
                                  <Col
                                    {...responsiveDesignButtons}
                                    className={
                                      data.selectedAddons.length === 1
                                        ? "flagTure"
                                        : "flagFalse"
                                    }
                                  >
                                    <ul style={{ marginLeft: "-25px" }}>
                                      <li
                                        style={{
                                          color: "#292929",
                                          opacity: "80%",
                                        }}
                                      >
                                        {" "}
                                        {JSON.parse(value.att_val_name).cn}{" "}
                                        &ensp; &nbsp;
                                      </li>
                                    </ul>
                                  </Col>
                                );
                              })}
                            </div>
                          ) : (
                            <Col lg={24}></Col>
                          )}
                        </Col>
                      </Row>
                    </Row>
                  </Card>
                </Col>
              </Row>
            );
          })}
        </div>
        <br />
        {values !== 0 ? (
          <div>
            <Row
              style={{
                position: "absolute",
                bottom: "66px",
                width: `${intViewportWidth}px`,
                backgroundColor: "#fff",
              }}
            >
              {" "}
              <Col
                {...responsiveDesignForColumn}
                style={{
                  paddingBottom: "3px",
                  paddingLeft: "9px",
                  paddingRight: "9px",
                }}
              >
                <Card
                  style={{ backgroundColor: "#EFEFEF", borderRadius: "1px" }}
                >
                  <Row style={{ margin: "0px" }}>
                    <Col {...responsiveDesignForItemData}>
                      <p
                        style={{
                          color: "black",
                          fontSize: "20px",
                          marginBottom: "0px",
                        }}
                      >
                        Total
                      </p>
                    </Col>

                    <Col {...responsiveDesignForImage}>
                      <b
                        style={{
                          color: "black",
                          float: "right",
                          fontSize: "20px",
                        }}
                      >
                        ${parseFloat(this.state.total).toFixed(2)}
                      </b>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "#fff",
                position: "absolute",
                bottom: "0",
                width: `${intViewportWidth}px`,
              }}
            >
              <Col
                {...responsiveDesignForColumn}
                style={{
                  padding: "7px",
                }}
              >
                <Card
                  style={{
                    backgroundColor: "#F35D5D",
                    position: "relative",
                  }}
                  onClick={this.onProceedPay}
                >
                  <Row style={{ marginTop: "0px" }}>
                    <Col
                      {...responsiveDesignForColumn}
                      style={{ textAlign: "center" }}
                    >
                      <b style={{ color: "white", fontSize: "18px" }}>
                        CONFIRM ORDER
                      </b>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <Row
              style={{
                backgroundColor: "#fff",
                textAlign: "center",
                left: "50%",
              }}
            >
              {" "}
              <Col
                {...responsiveDesignForColumn}
                style={{ padding: "7px", paddingTop: "30px" }}
              >
                <img
                  src={emptyBasketr}
                  alt="Empty"
                  style={{
                    borderRadius: "0px",
                    fontWeight: "500",
                    paddingLeft: "20px",
                    width: "60%",
                  }}
                />
              </Col>
              <Col
                {...responsiveDesignForColumn}
                style={{ padding: "7px", paddingTop: "55px" }}
              >
                <p
                  style={{
                    color: "#F35D5D",
                    float: "center",
                    fontSize: "16px",
                  }}
                >
                  GOOD FOOD IS WAITING FOR YOU
                </p>
              </Col>
              <Col
                {...responsiveDesignForColumn}
                style={{ padding: "7px", paddingTop: "60px" }}
              >
                <Row
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "60%",

                    transform: "translate(-80%,-80%)",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      color: "gray",

                      fontSize: "16px",
                    }}
                  >
                    Your cart is empty
                  </p>
                </Row>
                <Row
                  style={{
                    position: "absolute",
                    top: "65%",
                    left: "40%",
                    transform: "translate(-34%,-20%)",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      color: "gray",

                      fontSize: "16px",
                    }}
                  >
                    Add something from the menu
                  </p>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                margin: 0,
                position: "absolute",
                top: "80%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                textAlign: "center",
              }}
            >
              <Col
                {...responsiveDesignForColumn}
                style={{
                  padding: "7px",
                }}
              >
                <Card
                  style={{
                    border: "1px solid #F35D5D",
                    position: "relative",
                    height: "4rem",
                    width: "15rem",
                  }}
                  onClick={this.onBackToMenu}
                >
                  <p
                    style={{
                      color: "#F35D5D",
                      fontWeight: "700",
                      fontSize: "18px",
                      // marginTop: "-4px",
                      paddingLeft: "2px",
                      paddingTop: "5px",
                    }}
                  >
                    BROWSE MENU
                  </p>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default InsideCart;

import React from "react";
import { v4 as uuid } from "uuid";
import { Row, Col, Card, Checkbox, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import "./index.css";
class ComboItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: {},
      productPrice: "",
      comboDrinks: [],
      checkedValueFor: false,
      onCheckOfDrinks: "",
      hasModifiers: 0,
      comboDrinksAtts: [],
      drinksForCombo: [],
      drinksComboData: [],
      totaPrice: 0.0,
      drinksIdenti: undefined,
      subProductArray: [],
      modifiedItemsTotal: [],
    };
  }

  componentDidMount = () => {
    let modifiedItemsTotal = JSON.parse(
      localStorage.getItem("modifiedItemsTotal")
    );

    const product = JSON.parse(localStorage.getItem("productForBasket"));
    const comboInfo = JSON.parse(localStorage.getItem("comboIdentifyItems"));
    const drinksForCombo = JSON.parse(localStorage.getItem("drinksForCombo"));
    const comboDrinksAtts = JSON.parse(localStorage.getItem("comboDrinksAtts"));
    let activitySno = "";
    let drinksComboData = [];
    let comboDrinks = [];

    comboInfo.map((data) => {
      data.cdata.map((value) => {
        value.product_id.map((key) => {
          if (key.product_id === product.Prod_ID) {
            activitySno = data.activity_sn;
            if (activitySno === "2243") {
              drinksComboData = data.cdata[1].product_id;
              drinksComboData.map((combo) => {
                drinksForCombo.map((match) => {
                  if (combo.product_id === match.product_id) {
                    match.price = combo.product_price;
                    comboDrinks.push(match);
                  }
                });
              });
            } else {
              if (data.cdata.length !== 1) {
                drinksComboData = data.cdata[1].product_id;
                drinksComboData.map((combo) => {
                  drinksForCombo.map((match) => {
                    if (combo.product_id === match.product_id) {
                      match.price = combo.product_price;
                      comboDrinks.push(match);
                    }
                  });
                });
              }
            }
          }
        });
      });
    });
    let checkedId = "";
    let checkedValueFor = false;
    let defalultAddon = {};
    let defalultDrinkForDisplay = {};
    let element = "";

    for (let index = 0; index < comboDrinks.length; index += 1) {
      element = JSON.parse(comboDrinks[0].product_name).cn;
    }

    comboDrinks.map((data) => {
      if (JSON.parse(data.product_name).cn === element) {
        checkedId = data.product_id;
        defalultAddon = data;
        defalultDrinkForDisplay.att_name = data.product_name;
        defalultDrinkForDisplay.att_id = data.product_id;
        defalultDrinkForDisplay.attr_sn = data.product_sn;
        defalultDrinkForDisplay.selected = true;

        checkedValueFor = true;
        if (modifiedItemsTotal === null) {
          modifiedItemsTotal = [];
          modifiedItemsTotal.push(defalultDrinkForDisplay);
        } else {
          modifiedItemsTotal.push(defalultDrinkForDisplay);
        }
        //
        this.setState({
          defalultAddon: defalultAddon,
          onCheckOfDrinks: checkedId,
          checkedValueFor: checkedValueFor,
        });
      }
    });
    this.setState({
      product: product,
      comboDrinks: comboDrinks,
      productPrice: product.Price,
      comboDrinksAtts: comboDrinksAtts,
      drinksForCombo: drinksForCombo,
      drinksComboData: drinksComboData,
      modifiedItemsTotal: modifiedItemsTotal,
    });
  };

  onBackToItems = () => {
    const { history } = this.props;
    localStorage.setItem("modifiedItemsTotal", JSON.stringify([]));
    history.push("../addOnCart/index.js");
  };

  onChange = (e) => {
    const {
      comboDrinksAtts,
      drinksComboData,
      comboDrinks,
      product,
      modifiedItemsTotal,
    } = this.state;
    const updatedId = e.target.value.product_id;
    const checkedValueFor = e.target.checked;

    let modifiers = [];
    let modifierFlag = "";
    let drinksIdenti = {};
    let drinkWithModifier = [];
    let listPrice = "";

    let addedDrinkForDisplay = {};
    let drinkIndex = "";
    drinksComboData.map((data) => {
      if (updatedId === data.product_id) {
        this.setState({ checkedValue: true, checkedValueFor: checkedValueFor });
      } else {
        this.setState({
          checkedValue: false,
          checkedValueFor: checkedValueFor,
        });
      }
    });
    comboDrinksAtts.map((data) => {
      if (updatedId === data.product_id) {
        modifiers = data.atts;
      } else {
      }
    });
    if (checkedValueFor === true) {
      comboDrinks.map((match) => {
        if (updatedId === match.product_id) {
          drinksIdenti = match;

          modifierFlag = drinksIdenti.hasModifiers;
          drinkWithModifier.push(match);
          const index = drinkWithModifier.findIndex(
            (data) => data.product_id === updatedId
          );

          if (drinkWithModifier.length === 1) {
            drinkIndex = modifiedItemsTotal.findIndex(
              (x) => JSON.parse(x.att_name).cn === "No drinks"
            );
            addedDrinkForDisplay.att_name =
              drinkWithModifier[index].product_name;
            addedDrinkForDisplay.att_id = drinkWithModifier[index].product_id;
            addedDrinkForDisplay.attr_sn = drinkWithModifier[index].product_sn;
            addedDrinkForDisplay.selected = true;
            if (drinkIndex > -1) {
              modifiedItemsTotal[drinkIndex] = addedDrinkForDisplay;
            } else {
              const indexDrink = modifiedItemsTotal.length - 1;
              modifiedItemsTotal[indexDrink] = addedDrinkForDisplay;
            }

            product.listPrice = (
              parseFloat(product.Price) +
              parseFloat(drinkWithModifier[index].price)
            ).toFixed(2);

            listPrice = product.listPrice;
          } else {
            addedDrinkForDisplay.selected = false;
          }
        }
        this.setState({ hasModifiers: modifierFlag });
      });
    } else {
      comboDrinks.map((match) => {
        if (updatedId === match.product_id) {
          drinkWithModifier.push(match);
          const index = drinkWithModifier.findIndex(
            (data) => data.product_id === updatedId
          );
          if (drinkWithModifier.length === 1) {
            drinkIndex = modifiedItemsTotal.findIndex(
              (x) => JSON.parse(x.att_name).cn === "No drinks"
            );
            addedDrinkForDisplay.att_name =
              drinkWithModifier[index].product_name;
            addedDrinkForDisplay.att_id = drinkWithModifier[index].product_id;
            addedDrinkForDisplay.attr_sn = drinkWithModifier[index].product_sn;
            addedDrinkForDisplay.selected = false;
          }
          if (drinkIndex > -1) {
            modifiedItemsTotal[drinkIndex] = addedDrinkForDisplay;
          } else {
            const indexDrink = modifiedItemsTotal.length - 1;
            modifiedItemsTotal[indexDrink] = addedDrinkForDisplay;
          }

          if (
            product.listPrice === 0 ||
            product.listPrice === null ||
            product.listPrice === undefined
          ) {
            product.listPrice = (
              parseFloat(product.Price) - parseFloat(match.price)
            ).toFixed(2);
          } else {
            product.listPrice = (
              parseFloat(product.listPrice) - parseFloat(match.price)
            ).toFixed(2);
          }

          listPrice = product.listPrice;
          this.setState({ hasModifiers: 0 });
        }
      });
    }

    this.setState({
      product: product,
      onCheckOfDrinks: updatedId,
      modifiedItemsTotal: modifiedItemsTotal,
      totaPrice: listPrice,
      drinksIdenti: drinksIdenti,
    });
    localStorage.setItem("productForDrinkModifier", JSON.stringify(product));
    localStorage.setItem("selectedDRinkModifier", JSON.stringify(modifiers));
    localStorage.setItem(
      "modifiedItemsTotal",
      JSON.stringify(modifiedItemsTotal)
    );
  };

  drinkModifierPage = () => {
    const { history } = this.props;
    const { product, drinksIdenti } = this.state;
    const drinksForCartAddons = {};

    drinksForCartAddons.att_val_name = drinksIdenti.product_name;
    drinksForCartAddons.att_val_id = drinksIdenti.product_id;
    drinksForCartAddons.att_val_sn = drinksIdenti.product_sn;

    product.selectedAddons.push(drinksForCartAddons);
    localStorage.setItem("productForDrinkModifier", JSON.stringify(product));
    localStorage.setItem("selectedComboDrink", JSON.stringify(drinksIdenti));
    history.push("../drinkModifier/index.js");
  };

  onAddOnCart = () => {
    const { history } = this.props;
    const {
      product,
      drinksIdenti,
      subProductArray,
      defalultAddon,
      modifiedItemsTotal,
    } = this.state;

    const drinksForCartAddons = {};
    const basket = JSON.parse(localStorage.getItem("basket"));
    const combinationId = uuid().replace(/-/g, "").toUpperCase();
    const firstSubProductObject = {
      Modifiers: product.Modifiers,
      prodNum: `${product.Prod_IDFor}`,
    };

    let subProductObject = {};
    if (
      drinksIdenti === undefined ||
      drinksIdenti === {} ||
      drinksIdenti === null
    ) {
      subProductObject = {
        Modifiers: [],
        prodNum: `${defalultAddon.product_sn}`,
      };
      drinksForCartAddons.att_val_name = defalultAddon.product_name;

      drinksForCartAddons.att_val_id = defalultAddon.product_id;
      drinksForCartAddons.att_val_sn = defalultAddon.product_sn;
    } else {
      subProductObject = {
        Modifiers: [],
        prodNum: `${drinksIdenti.product_sn}`,
      };
      drinksForCartAddons.att_val_name = drinksIdenti.product_name;
      drinksForCartAddons.att_val_id = drinksIdenti.product_id;
      drinksForCartAddons.att_val_sn = drinksIdenti.product_sn;
    }

    if (drinksForCartAddons.att_val_sn !== undefined) {
      product.selectedAddons.push(drinksForCartAddons);
    }

    if (drinksForCartAddons.att_val_id !== undefined) {
      subProductArray.push(firstSubProductObject);
      subProductArray.push(subProductObject);
      product.SubProduct = subProductArray;
      product.headerArray = modifiedItemsTotal;
      product.combinationId = combinationId;
      basket.push(product);
      let total = 0.0;

      for (let i = 0; i < basket.length; i += 1) {
        if (basket[i].listPrice) {
          total +=
            parseFloat(basket[i].listPrice).toFixed(2) * basket[i].Quantity;
        } else {
          total += parseFloat(basket[i].Price).toFixed(2) * basket[i].Quantity;
        }
      }

      localStorage.setItem("total", parseFloat(total).toFixed(2));
      localStorage.setItem("basket", JSON.stringify(basket));
      localStorage.setItem("modifiedItemsTotal", JSON.stringify([]));
      history.push("../../thanks");
    } else {
      message.error("Please select one item");
    }
  };

  render() {
    const {
      product,
      productPrice,
      comboDrinks,
      checkedValueFor,
      onCheckOfDrinks,
      hasModifiers,
      totaPrice,
      modifiedItemsTotal,
    } = this.state;
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };

    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };

    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };

    const intViewportWidth = window.innerWidth;
    const intFrameHeight = window.innerHeight;
    return (
      <div
        style={{
          margin: "0px",
          height: `${intFrameHeight}px`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          // height: "639px",
          backgroundColor: "#fff",
        }}
      >
        <Row>
          <Col {...responsiveDesignForColumn} style={{ marginBottom: "-70px" }}>
            <LeftOutlined
              onClick={this.onBackToItems}
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                color: "black",
                marginTop: "15px",
                paddingLeft: "10px",
                position: "absolute",
              }}
              size="large"
              fontWeight="bold"
            />

            <img
              src={product.Image_Url}
              alt="product"
              width="100%"
              height="55%"
              style={{ borderRadius: "0px" }}
            />

            <Card style={{ backgroundColor: "#EFEFEF", borderRadius: "0px" }}>
              <Row style={{ margin: "0px" }}>
                <Col {...responsiveDesignForItemData}>
                  <b style={{ fontSize: "3.8vw" }}>{product.Name} </b>
                </Col>
                <Col {...responsiveDesignForImage}>
                  <p
                    style={{
                      color: "black",
                      fontSize: "20px",
                      paddingLeft: "4px",
                    }}
                  >
                    ${totaPrice === 0.0 ? productPrice : totaPrice}
                  </p>
                </Col>
              </Row>
              <Row>
                <div style={{ maxHeight: "55px", overflowY: "scroll" }}>
                  {modifiedItemsTotal.map((values) => {
                    if (values.selected) {
                      return (
                        <div>
                          {values.selected === true ? (
                            <div>
                              <Col {...responsiveDesignForColumn}>
                                <b style={{ fontSize: "3.3vw" }}>
                                  {" "}
                                  {JSON.parse(values.att_name).en}{" "}
                                </b>
                              </Col>

                              {values.att_val_info ? (
                                values.att_val_info.map((data) => {
                                  if (data.checked) {
                                    return (
                                      <div>
                                        {data.checked === true ? (
                                          <ul>
                                            <li>
                                              {" "}
                                              {JSON.parse(data.att_val_name).cn}
                                              &nbsp;{" "}
                                            </li>
                                          </ul>
                                        ) : (
                                          <ul style={{ display: "none" }}>
                                            <li>
                                              {" "}
                                              {JSON.parse(data.att_val_name).cn}
                                              &nbsp;{" "}
                                            </li>
                                          </ul>
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <ul style={{ display: "none" }}>
                                        <li> </li>
                                      </ul>
                                    );
                                  }
                                })
                              ) : (
                                <Col style={{ display: "none" }}></Col>
                              )}
                            </div>
                          ) : (
                            <Col
                              {...responsiveDesignForColumn}
                              style={{ display: "none" }}
                            >
                              <b style={{ fontSize: "3.3vw" }}></b>
                            </Col>
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          <Col
                            {...responsiveDesignForColumn}
                            style={{ display: "none" }}
                          >
                            <b style={{ fontSize: "3.3vw" }}>
                              {JSON.parse(values.att_name).en}{" "}
                            </b>
                          </Col>
                        </div>
                      );
                    }
                  })}
                </div>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row {...responsiveDesignForColumn}>
          <Col {...responsiveDesignForColumn}>
            <div
              style={{
                paddingLeft: "20px",
                paddingRight: "10px",
                paddingBottom: "6px",
                paddingTop: "30px",
              }}
            >
              <b style={{ fontSize: "4.4vw" }}>Choose Your Drink</b>
            </div>
            <div
              style={{
                maxHeight: "400px",
                overflowY: "scroll",
                backgroundColor: "#fff",
                paddingBottom: "33px",
              }}
            >
              {comboDrinks.map((data) => {
                return (
                  <Form style={{ paddingLeft: "20px" }}>
                    <FormItem>
                      <Col>
                        <Checkbox
                          checked={
                            data.product_id === onCheckOfDrinks &&
                            checkedValueFor === true
                              ? true
                              : false
                          }
                          value={data}
                          style={{ fontSize: "16px" }}
                          onChange={this.onChange}
                        >
                          {JSON.parse(data.product_name).cn} (+${data.price})
                        </Checkbox>
                      </Col>
                    </FormItem>
                  </Form>
                );
              })}
            </div>
          </Col>
        </Row>
        {hasModifiers === 0 ? (
          <Row
            style={{
              position: "fixed",
              bottom: 0,
              width: `${intViewportWidth}px`,
              paddingBottom: "9px",
              textAlign: "center",
              paddingLeft: "8px",
              paddingRight: "9px",
            }}
          >
            <Col {...responsiveDesignForColumn}>
              <Card
                style={{
                  backgroundColor: "#F35D5D",
                }}
                onClick={() => this.onAddOnCart()}
              >
                <Row>
                  <Col {...responsiveDesignForColumn}>
                    <b
                      style={{
                        color: "white",
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      ADD TO CART
                    </b>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row
            style={{
              position: "fixed",
              bottom: 0,
              width: `${intViewportWidth}px`,
              paddingBottom: "9px",
              paddingLeft: "8px",
              paddingRight: "9px",
              textAlign: "center",
            }}
          >
            <Col {...responsiveDesignForColumn}>
              <Card
                style={{
                  backgroundColor: "#F35D5D",
                }}
                onClick={() => this.drinkModifierPage()}
              >
                <Row>
                  <Col {...responsiveDesignForColumn}>
                    <b
                      style={{
                        color: "white",
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      Add Modifiers?
                    </b>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}

        <br />
      </div>
    );
  }
}

export default ComboItem;

import React from "react";
import OwlCarousel from "react-owl-carousel3";
import ProductBox from "../home/ProductBox";

/* import limitedTimeOfferImage from "../../../src/images/R1BeefBrisketandTendonRice.jpg";
import riceImage from "../../../src/images/G2BlackPepperBeef.jpg";
import asainFavourites from "../../../src/images/MeeSiam.jpg";
import englishMuffins from "../../../src/images/Kayamuffin.jpg"; */
/* import pizzaImage from "../../../src/images/pizza.jpg";
import healthyImage from "../../../src/images/healthy.jpg";
import vegetarianImage from "../../../src/images/vegetarian.jpg"; */
import chineseImage from "../../../src/images/chinese.jpg";
// import desertImage from "../../../src/images/desert.jpg";
import chickenImage from "../../../src/images/chicken.jpeg";
// import indianImage from "../../../src/images/indian.jpg";
import axios from "axios";
// import { URL } from "../config";

// import { Select } from "antd";

class CategoriesCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryItems: [],
      catId: 13,
    };
  }

  componentDidMount() {
    this.categoryList();
  }
  componentWillMount = () => {
    const { categoryItems } = this.state;
    this.setState({ categoryItems: categoryItems });
  };
  categoryList = () => {
    axios({
      method: "get",
      url: URL.concat("category"),
    }).then((response) => {
      // console.log("result from /category is=========>>>>>>>>", response);
      const categoryItems = response.data;
      this.setState({ categoryItems: categoryItems });
    });
  };

  setCategoryId = (key) => {
    this.setState({ catId: key });
  };

  render() {
    const { categoryItems, catId } = this.state;
    const intViewportWidth = window.innerWidth;
    // console.log("categoryItems========>>>>>>>>>>", categoryItems);
    return (
      <div
      // className="horizontalFor"
      >
        <OwlCarousel
          nav
          loop
          {...options}
          className="owl-carousel-category owl-theme"
          style={{
            maxWidth: `${intViewportWidth}`,
            overflowX: "scroll",
            // WebkitOverflowScrolling: "auto",
          }}
        >
          {categoryItems.map((Select) => {
            return (
              <div className="item">
                <ProductBox
                  boxClass={
                    catId === Select.Catrgory_ID
                      ? "osahan-category-item-selected"
                      : "osahan-category-item"
                  }
                  title={Select.Name}
                  catId={Select.Catrgory_ID}
                  image={chineseImage}
                  imageClass="img-fluid"
                  imageAlt="carousel"
                  linkUrl="#"
                  setCategoryId={this.setCategoryId}
                />
              </div>
            );
          })}
          <div className="item">
            <ProductBox
              boxClass="osahan-category-item"
              title="Chicken"
              catId="10"
              image={chickenImage}
              imageClass="img-fluid"
              imageAlt="carousel"
              linkUrl="#"
            />
          </div>

          {/*     <div className="item">
          <ProductBox
            boxClass="osahan-category-item"
            title="Rice Dishes"
            // counting='120'
            image={riceImage}
            imageClass="img-fluid"
            imageAlt="carousel"
            linkUrl="#"
          />
        </div>
        <div className="item">
          <ProductBox
            boxClass="osahan-category-item"
            title="Asain Favourite"
            // counting='130'
            image={asainFavourites}
            imageClass="img-fluid"
            imageAlt="carousel"
            linkUrl="#"
          />
        </div>
        <div className="item">
          <ProductBox
            boxClass="osahan-category-item"
            title="English Muffins"
            // counting='120'
            image={englishMuffins}
            imageClass="img-fluid"
            imageAlt="carousel"
            linkUrl="#"
          />
        </div>
        <div className="item">
          <ProductBox
            boxClass="osahan-category-item"
            title="Indian"
            // counting='156'
            image={indianImage}
            imageClass="img-fluid"
            imageAlt="carousel"
            linkUrl="#"
          />
        </div>
        <div className="item">
          <ProductBox
            boxClass="osahan-category-item"
            title="Chinese"
            // counting='111'
            image={chineseImage}
            imageClass="img-fluid"
            imageAlt="carousel"
            linkUrl="#"
          />
        </div>
        <div className="item">
          <ProductBox
            boxClass="osahan-category-item"
            title="Dessert"
            // counting='56'
            image={desertImage}
            imageClass="img-fluid"
            imageAlt="carousel"
            linkUrl="#"
          />
        </div>
        <div className="item">
          <ProductBox
            boxClass="osahan-category-item"
            title="Chicken"
            // counting='40'
            image={chickenImage}
            imageClass="img-fluid"
            imageAlt="carousel"
            linkUrl="#"
          />
        </div> */}
        </OwlCarousel>
      </div>
    );
  }
}

const options = {
  responsive: {
    0: {
      items: 4,
    },
    320: {
      items: 4,
    },
    330: {
      items: 5,
    },
    500: {
      items: 5,
    },
    600: {
      items: 5,
    },
    700: {
      items: 5,
    },
    1000: {
      items: 6,
    },
    1200: {
      items: 6,
    },
  },
  loop: false,
  lazyLoad: false,
  // autoplay: true,
  dots: false,
  autoplaySpeed: 1000,
  autoplayTimeout: 2000,
  autoplayHoverPause: false,
  nav: false,
  // WebkitOverflowScrolling: true,
  navText: ["", ""],
};

export default CategoriesCarousel;

import React from "react";

import { Row, Col, Card } from "antd";
import "antd/dist/antd.css";

class AdditionalCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      basket: [],
      basketQunatityItems: [],
      productForDisplay: {},
      selectedAddons: [],
    };
  }

  componentDidMount = () => {
    const basket = JSON.parse(localStorage.getItem("basket"));

    const selectedProdcutId = JSON.parse(
      localStorage.getItem("selectedProductId")
    );

    const index = basket.findIndex(
      (data) => data.Prod_ID === selectedProdcutId
    );

    const product = basket[index];

    this.setState({
      basket: basket,
      productForDisplay: product,
      selectedAddons: product.selectedAddons,
    });
  };

  onAddNewCustom = () => {
    const { history } = this.props;

    history.push("../addOnCart/index.js");
  };

  onRepeatSameCustom = () => {
    const { history } = this.props;
    const { basket } = this.state;

    const itemId = JSON.parse(localStorage.getItem("selectedProductId"));

    const index = basket.findIndex((item) => item.Prod_ID === itemId);

    basket[index].Quantity = parseFloat(basket[index].Quantity) + 1;

    let total = 0.0;
    for (let i = 0; i < basket.length; i += 1) {
      if (basket[i].listPrice === 0 || !basket[i].listPrice) {
        total += parseFloat(basket[i].Price).toFixed(2) * basket[i].Quantity;
      } else {
        total +=
          parseFloat(basket[i].listPrice).toFixed(2) * basket[i].Quantity;
      }
    }
    localStorage.setItem("basket", JSON.stringify(basket));
    localStorage.setItem("total", parseFloat(total).toFixed(2));

    history.push("../../thanks");
  };

  render() {
    const { productForDisplay } = this.state;
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };

    const responsiveDesignDineInAndtakeAway = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 12,
      sm: 12,
      md: 12,
    };

    const intFrameHeight = window.innerHeight;
    return (
      <div
        style={{
          margin: "0px",
          height: `${intFrameHeight}px`,

          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          // height: "575px",
          backgroundColor: "#fff",
        }}
      >
        <Row>
          <Col {...responsiveDesignForColumn}>
            <img
              src={productForDisplay.Image_Url}
              alt="Girl in a jacket"
              width="100%"
              height="450px"
              style={{ borderRadius: "0px" }}
            />

            <h2
              style={{
                color: "#fff",
                marginTop: "-65px",
                fontWeight: "700",
                paddingBottom: "15px",
                paddingLeft: "10px",
              }}
            >
              Specify your dish
            </h2>
            <Card style={{ backgroundColor: "#EFEFEF", borderRadius: "0px" }}>
              <Row style={{ margin: "0px" }}>
                <Col {...responsiveDesignForColumn}>
                  <b>{productForDisplay.Name} </b>
                </Col>

                <Col lg={24} style={{ marginTop: "0px" }}>
                  <b
                    style={{
                      color: "gray",
                      fontSize: "16px",
                    }}
                  >
                    ${productForDisplay.Price}
                  </b>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row style={{ backgroundColor: "#fff", paddingTop: "15px" }}>
          <Col
            {...responsiveDesignDineInAndtakeAway}
            style={{
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingBottom: "10px",
              paddingRight: "5px",
            }}
          >
            <Card
              style={{
                backgroundColor: "#fff",
                position: "relative",
                height: "3.0rem",
                border: "1px solid #F35D5D",
              }}
              onClick={this.onAddNewCustom}
            >
              <p
                style={{
                  color: "#F35D5D",
                  fontWeight: "700",
                  fontSize: "12px",

                  paddingLeft: "4.5px",
                }}
              >
                NEW CUSTOMIZATION
              </p>
            </Card>
          </Col>
          <Col
            {...responsiveDesignDineInAndtakeAway}
            style={{
              paddingTop: "10px",
              paddingLeft: "5px",
              paddingBottom: "0px",
              paddingRight: "20px",
            }}
          >
            <Card
              style={{
                backgroundColor: "#F35D5D",
                position: "relative",
                height: "3.0rem",
              }}
              onClick={this.onRepeatSameCustom}
            >
              <p
                style={{
                  color: "white",
                  fontWeight: "700",
                  fontSize: "12px",
                  marginTop: "-4px",
                  paddingLeft: "8px",
                }}
              >
                REPEAT PREVIOUS CUSTOMIZATION
              </p>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AdditionalCart;

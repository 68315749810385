import React from "react";
import { Row, Col, Card, Spin, Divider } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import "antd/dist/antd.css";

class OrderPlacingAtCounter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
      orderNumber: "",
      orderStatus: "",
      errorFlag: false,
      basket: [],
      totalAmount: 0.0,
      imageInfo: {},
      pagerValue: "",
      staffDiscount: {},
      subTotal: 0.0,
      discountedAmount: 0.0,
      totalAmount: 0.0,
      discountFlag: false,
    };
  }

  componentDidMount = () => {
    const basket = JSON.parse(localStorage.getItem("basket"));
    const orderValues = JSON.parse(localStorage.getItem("orderDetails"));
    const orderMessages = JSON.parse(localStorage.getItem("storeMessages"));
    const imageInfo = JSON.parse(localStorage.getItem("imagesForMessages"));
    const pagerValue = JSON.parse(localStorage.getItem("pagerValue"));
    const discountInfo = JSON.parse(
      localStorage.getItem("pagerAndPickupFlags")
    );
    const staffDiscount = discountInfo.StaffDiscount;
    const subTotal = parseFloat(localStorage.getItem("total")).toFixed(2);
    const discountFlag = JSON.parse(localStorage.getItem("discountFlag"));

    let totalAmount = 0.0;
    if (discountFlag === false) {
      totalAmount = parseFloat(localStorage.getItem("total")).toFixed(2);
    } else {
      totalAmount = parseFloat(localStorage.getItem("amountTotal")).toFixed(2);
    }

    let discountedAmount = 0.0;
    if (discountFlag === true) {
      discountedAmount = parseFloat(
        localStorage.getItem("discountedAmount")
      ).toFixed(2);
    } else {
      discountedAmount = parseFloat(0).toFixed(2);
    }

    let orderNumber = "";
    let orderStatus = "";
    let errorFlag = false;
    orderNumber = orderValues.order_number;
    if (
      orderNumber === null ||
      orderNumber === undefined ||
      orderNumber === ""
    ) {
      errorFlag = true;
      orderNumber = orderValues.order_number;
      orderStatus = orderMessages.PAC.Failed;
    } else {
      errorFlag = false;
      orderNumber = orderValues.order_number;
      orderStatus = orderMessages.PAC.Success;
    }
    this.setState({
      orderNumber: orderNumber,
      orderStatus: orderStatus,
      errorFlag: errorFlag,
      basket: basket,
      totalAmount: totalAmount,
      imageInfo: imageInfo,
      pagerValue,
      staffDiscount: staffDiscount,
      subTotal: subTotal,
      discountedAmount: discountedAmount,
      totalAmount: totalAmount,
      discountFlag: discountFlag,
    });
  };

  onGetBackToHome = () => {
    localStorage.setItem("basketQunatityItems", JSON.stringify([]));
    localStorage.setItem("basket", JSON.stringify([]));
    localStorage.setItem("total", 0);
    localStorage.setItem("amountTotal", 0);
    localStorage.setItem("itemsQuantity", 0);
    localStorage.setItem("discountedAmount", 0);
    localStorage.setItem("discountFlag", JSON.stringify(false));

    localStorage.setItem("placingDetails", JSON.stringify([]));
    localStorage.setItem("allProductsData", JSON.stringify([]));
    localStorage.setItem("orderDetails", JSON.stringify({}));
    localStorage.setItem("userValues", JSON.stringify({}));
    localStorage.setItem("pagerValue", JSON.stringify(""));
    localStorage.setItem("scheduleDate", JSON.stringify(""));
    localStorage.setItem("successIndicator", JSON.stringify([]));

    localStorage.removeItem("Category_Id");
    const { history } = this.props;
    history.push("../../thanks");
  };
  onGetBackToPayment = () => {
    const { history } = this.props;

    history.push("../payment/index.js");
  };
  render() {
    const {
      orderStatus,
      loading,
      orderNumber,
      errorFlag,
      basket,
      totalAmount,
      imageInfo,
      pagerValue,
      staffDiscount,
      subTotal,
      discountedAmount,
      discountFlag,
    } = this.state;

    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };
    const responsiveDesignDish = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };
    const responsiveDesignForSkip = {
      xxl: 18,
      xl: 18,
      lg: 18,
      xs: 18,
      sm: 18,
      md: 18,
    };
    const responsiveDesignForQuantity = {
      xxl: 2,
      xl: 2,
      lg: 2,
      xs: 2,
      sm: 2,
      md: 2,
    };
    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };
    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };

    const responsiveDesignPager = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };
    const responsiveDesignDish1 = {
      xxl: 6,
      xl: 6,
      lg: 6,
      xs: 6,
      sm: 6,
      md: 6,
    };

    const intFrameHeight = window.innerHeight;
    const intViewportWidth = window.innerWidth;

    const antIcon = <SyncOutlined spin />;
    return (
      <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{ position: "relative", top: "25em", color: "green" }}
        size="large"
      >
        <div
          style={{
            margin: "0px",
            height: `${intFrameHeight}px`,

            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",

            backgroundColor: "#fff",
          }}
        >
          <Row style={{ paddingTop: "40px" }}>
            <Col {...responsiveDesignForColumn} style={{ textAlign: "center" }}>
              {errorFlag === true ? (
                <img
                  style={{
                    fontWeight: "700",
                    width: "50%",

                    marginTop: "-5px",
                  }}
                  src={imageInfo.PACFailed}
                  alt=""
                />
              ) : (
                <img
                  style={{
                    fontWeight: "700",
                    width: "50%",

                    marginTop: "-5px",
                  }}
                  src={imageInfo.PACSuccess}
                  alt=""
                />
              )}
            </Col>
          </Row>
          <Row style={{ backgroundColor: "#fff" }}>
            <Col
              {...responsiveDesignForColumn}
              style={{
                paddingTop: "20px",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingBottom: "8px",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: "17px",

                  color: "black",
                }}
              >
                {orderStatus}
              </p>
            </Col>
            <Col
              {...responsiveDesignForColumn}
              style={{ textAlign: "center", paddingBottom: "8px" }}
            >
              {errorFlag === true ? (
                <p
                  style={{
                    fontSize: "20px",

                    color: "gray",
                  }}
                >
                  Sorry !
                </p>
              ) : (
                <b
                  style={{
                    fontSize: "24px",

                    color: "black",
                  }}
                >
                  Your Order Number
                </b>
              )}
            </Col>

            <br />

            <Col
              {...responsiveDesignForColumn}
              style={{ textAlign: "center", paddingTop: "10px" }}
            >
              <b
                style={{
                  fontSize: "30px",

                  color: "black",
                }}
              >
                {orderNumber}
              </b>
            </Col>
            {pagerValue === "" || errorFlag === true ? (
              <Col
                {...responsiveDesignForColumn}
                style={{ display: "none", paddingTop: "10px" }}
              >
                <b
                  style={{
                    fontSize: "30px",

                    color: "black",
                  }}
                >
                  {pagerValue}
                </b>
              </Col>
            ) : (
              <Col
                {...responsiveDesignForColumn}
                style={{ textAlign: "center", paddingTop: "10px" }}
              >
                <Row style={{ backgroundColor: "#fff" }}>
                  <Col {...responsiveDesignPager}>
                    <b
                      style={{
                        fontSize: "20px",
                        color: "black",
                        marginLeft: "25%",
                      }}
                    >
                      Pager Number:
                    </b>
                  </Col>
                  <Col {...responsiveDesignDish} style={{ textAlign: "left" }}>
                    <b
                      style={{
                        fontSize: "20px",
                        color: "black",
                        marginLeft: "-8%",
                      }}
                    >
                      {pagerValue}
                    </b>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>

          <br />
          {errorFlag === false ? (
            <Row style={{ backgroundColor: "#fff", paddingBottom: "100px" }}>
              <Col
                {...responsiveDesignForColumn}
                style={{
                  paddingLeft: "7px",
                  paddingRight: "7px",
                  paddingTop: "7px",
                }}
              >
                <Card
                  style={{
                    backgroundColor: "#fff",
                    position: "relative",
                    boxShadow: " 0px 2px 6px -3px",
                    paddingBottom: "30px",
                  }}
                >
                  <Row
                    style={{
                      marginBottom: "-8px",
                      textAlign: "center",
                      fontSize: "16px",
                      display: "block",
                    }}
                  >
                    <Col>
                      <b>ORDER SUMMARY</b>
                    </Col>
                  </Row>
                  <Divider style={{ margin: "20px" }} />
                  <div>
                    {basket.map((data) => {
                      return (
                        <Row style={{ marginBottom: "-8px" }}>
                          <Col {...responsiveDesignForSkip}>
                            <b style={{ color: "#512C1D" }}>{data.Name}</b>
                          </Col>
                          <Col {...responsiveDesignForQuantity}>
                            <b style={{ color: "#512C1D" }}>
                              x{data.Quantity}{" "}
                            </b>
                          </Col>
                          <Col {...responsiveDesignForImage}>
                            <b style={{ float: "right", color: "#512C1D" }}>
                              $
                              {data.Customizable === 0 || data.listPrice === 0.0
                                ? parseFloat(data.Price).toFixed(2)
                                : parseFloat(data.listPrice).toFixed(2)}
                            </b>
                          </Col>
                          <Col {...responsiveDesignForColumn}>
                            {data.selectedAddons ? (
                              <div
                                style={{
                                  display: "flex",
                                  maxWidth: `${intViewportWidth}`,
                                  overflowX: "scroll",
                                }}
                              >
                                {data.selectedAddons.map((value) => {
                                  return (
                                    <Col {...responsiveDesignDish}>
                                      <ul style={{ marginLeft: "-25px" }}>
                                        <li
                                          style={{
                                            color: "#292929",
                                            opacity: "80%",
                                          }}
                                        >
                                          {" "}
                                          {
                                            JSON.parse(value.att_val_name).cn
                                          }{" "}
                                          &ensp; &nbsp;
                                        </li>
                                      </ul>
                                    </Col>
                                  );
                                })}
                              </div>
                            ) : (
                              <Col lg={24}></Col>
                            )}
                          </Col>
                          &ensp;
                        </Row>
                      );
                    })}
                  </div>
                  <Divider style={{ margin: "15px" }} />

                  <Row
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    <Col {...responsiveDesignForSkip}>
                      <b style={{ color: "#512C1D" }}>SUB-TOTAL</b>
                    </Col>
                    <Col {...responsiveDesignDish1}>
                      <b style={{ color: "#512C1D", float: "right" }}>
                        ${subTotal}
                      </b>
                    </Col>
                  </Row>
                  {discountFlag === true ? (
                    <Row
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      <Col {...responsiveDesignForSkip}>
                        <b style={{ color: "#512C1D" }}>
                          {" "}
                          {staffDiscount.Message3}
                        </b>
                      </Col>
                      <Col {...responsiveDesignDish1}>
                        <b style={{ color: "#512C1D", float: "right" }}>
                          - ${discountedAmount}
                        </b>
                      </Col>
                    </Row>
                  ) : (
                    <Row
                      style={{
                        fontSize: "16px",
                        display: "none",
                      }}
                    ></Row>
                  )}

                  <Divider style={{ margin: "5px" }} />
                  <Row
                    style={{
                      fontSize: "16px",
                      marginBottom: "-35px",
                    }}
                  >
                    <Col {...responsiveDesignForSkip}>
                      <b style={{ color: "#512C1D" }}>FINAL-TOTAL</b>
                    </Col>
                    <Col {...responsiveDesignDish1}>
                      <b style={{ color: "#512C1D", float: "right" }}>
                        ${totalAmount}
                      </b>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ) : (
            <Col {...responsiveDesignForColumn} style={{ display: "none" }}>
              <b style={{ fontSize: "3.3vw" }}></b>
            </Col>
          )}

          {errorFlag === true ? (
            <Row
              style={{
                margin: 0,
                position: "absolute",
                top: "80%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                textAlign: "center",
                backgroundColor: "#fff",
              }}
            >
              <Col
                {...responsiveDesignForColumn}
                style={{
                  padding: "7px",
                }}
              >
                <Card
                  style={{
                    border: "1px solid #F35D5D",
                    position: "relative",
                    height: "4rem",
                    width: "15rem",
                  }}
                  onClick={this.onGetBackToPayment}
                >
                  <p
                    style={{
                      color: "#F35D5D",
                      fontWeight: "700",
                      fontSize: "18px",
                      // marginTop: "-4px",
                      paddingLeft: "2px",
                      paddingTop: "5px",
                    }}
                  >
                    Try Again
                  </p>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row
              style={{
                paddingTop: "9px",
                paddingBottom: "8px",
                width: `${intViewportWidth}px`,
                position: "fixed",
                bottom: 0,
                backgroundColor: "#fff",
              }}
            >
              <Col
                {...responsiveDesignForColumn}
                style={{
                  paddingLeft: "7px",
                  paddingRight: "7px",
                  paddingTop: "7px",
                }}
              >
                <Card
                  style={{
                    backgroundColor: "#F35D5D",
                    position: "relative",
                  }}
                  onClick={this.onGetBackToHome}
                >
                  <Row style={{ margin: "0px" }}>
                    <Col
                      {...responsiveDesignForColumn}
                      style={{ textAlign: "center" }}
                    >
                      <b
                        style={{
                          color: "white",
                          fontWeight: "700",
                          fontSize: "20px",
                        }}
                      >
                        BACK TO MAIN MENU
                      </b>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </Spin>
    );
  }
}

export default OrderPlacingAtCounter;

import React from "react";
import axios from "axios";
import { Row, Spin } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { storeApi } from "./config.js";

class Landing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentWillMount = () => {
    const { history } = this.props;
    let browserUrl = window.location.href;
    let lastslashindex = browserUrl.lastIndexOf("/");
    browserUrl = browserUrl.substring(lastslashindex + 1);
    const config = {
      method: "get",
      url: `${storeApi}`.concat(`${browserUrl}`),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ apiDetails: response.data });
          localStorage.setItem("apiDetails", JSON.stringify(response.data));
          history.push("/sample");
        }
      })
      .catch(function (res) {
        console.log(res);
      });
  };

  render() {
    const { loading } = this.state;
    const antIcon = <SyncOutlined spin />;
    return (
      <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{ position: "relative", top: "20em", color: "#f35d5d" }}
        size="large"
      >
        <Row
          style={{
            textAlign: "center",
            backgroundColor: "#fff",
          }}
        >
          {" "}
          <h1></h1>
        </Row>
      </Spin>
    );
  }
}

export default Landing;

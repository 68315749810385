import React from "react";
import { Row, Col, Spin, Card } from "antd";
import location from "./../images/location.png";
import axios from "axios";
import moment from "moment";
import "antd/dist/antd.css";
import { SyncOutlined } from "@ant-design/icons";
import "./sample.css";
import "./../customCss/style.css";

class Sample extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      landingInfo: {},
      loading: false,
      storeAddress: {},
      storeName: "",
      landingColors: {},
      topBarColor: "",
      bottomBarColor: "",
      bottomText: "",
      pickUpTimings: [],
      apiDetails: {},
    };
  }

  componentDidMount = () => {
    const basket = [];
    const total = 0.0;
    localStorage.setItem("basket", JSON.stringify(basket));
    localStorage.setItem("total", total);
    localStorage.setItem("userValues", JSON.stringify({}));
    localStorage.setItem("successIndicator", JSON.stringify([]));

    this.detailsOfDevice();
  };

  detailsOfDevice = () => {
    const apiDetails = JSON.parse(localStorage.getItem("apiDetails"));

    this.setState({ loading: true, apiDetails: apiDetails });
    const md5 = require("md5");
    const uri = "api/mobile/getStoreByDeviceNo";
    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    const Request_Body = JSON.stringify({ deviceNo: apiDetails.deviceID });
    const sign = md5(
      apiDetails.appKey +
        apiDetails.appSecret +
        uri +
        Request_Body +
        secondsSinceEpoch
    ).toString();

    const data = JSON.stringify({ deviceNo: `${apiDetails.deviceID}` });

    const config = {
      method: "post",
      url: `${apiDetails.apiDomain}/`.concat(`${uri}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${apiDetails.tenantID}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${apiDetails.appKey}`,
        "Serial-Number": `${apiDetails.deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: data,
    };

    let storeId = "";

    axios(config)
      .then((response) => {
        const storeInfo = response.data.storeInfos;
        storeInfo.map((data) => {
          storeId = data.store_id;
          const storeMessages = JSON.parse(data.store_note).Payment;
          const storeName = JSON.parse(data.store_name).cn;
          const landingInfo = JSON.parse(data.store_note).Images;
          const landingColors = JSON.parse(data.store_note);
          const work_time = JSON.parse(data.work_time);
          let storeStartTime = "";
          let storeEndTime = "";
          var date = new Date();
          let modifyDate = date.toString();
          const getHours = modifyDate.slice(16, 18);
          const getMinutes = modifyDate.slice(19, 21);
          const pickUpTime = getHours + ":" + getMinutes;
          let momentTime = moment(pickUpTime);
          work_time.real.map((data) => {
            var toDay = date.getDay();
            if (toDay === 0) {
              toDay = 7;
            }
            if (toDay === data.id) {
              data.time.map((time) => {
                storeStartTime = time.start;
                storeEndTime = time.end;
              });
            }
          });
          const result = [];
          function intervals(start, end) {
            var start = moment(start, "hh:mm ").add(5, "m");
            var end = moment(end, "hh:mm ");
            var current = moment(start);
            if (momentTime._i >= current._i) {
              while (current <= end) {
                result.push(current.format("HH:mm ").slice(0, 5));
                current.add(15, "minutes");
              }
            }
          }

          intervals(`${storeStartTime}`, `${storeEndTime}`);
          // intervals("09:21", "14:30");
          var curDate = new Date();
          curDate = curDate.toDateString();
          var times = result.map((t) => {
            return new Date(curDate + " " + t);
          });

          var now = new Date();
          var min = Math.abs(now - times[0]);
          var result1 = "";
          for (let i = 1; i < times.length; i++) {
            if (Math.abs(now - times[i]) <= min) {
              min = Math.abs(now - times[i]);
              result1 = result[i];
            }
          }

          var a = result.indexOf(result1);
          let pickUpTimings = [];
          for (var i = a + 1; i < result.length; ++i) {
            pickUpTimings.push(result[i]);
          }
          const topBarColor = landingColors.LandingPage.TopBarColorCode;
          const bottomBarColor = landingColors.LandingPage.Footer.ColorCode;
          const bottomText = landingColors.LandingPage.Footer.Text;
          const pagerInfo = JSON.parse(data.store_note).Pager;
          localStorage.setItem("storeDetails", JSON.stringify(data));
          localStorage.setItem("storeMessages", JSON.stringify(storeMessages));
          localStorage.setItem("pickUpTimings", JSON.stringify(work_time));
          localStorage.setItem(
            "imagesForMessages",
            JSON.stringify(landingInfo)
          );
          localStorage.setItem("pagerInfo", JSON.stringify(pagerInfo));
          localStorage.setItem(
            "pagerAndPickupFlags",
            JSON.stringify(landingColors)
          );
          localStorage.setItem("storeId", storeId);
          this.setState({
            landingInfo: landingInfo,
            storeAddress: data,
            storeName: storeName,
            landingColors: landingColors,
            topBarColor: topBarColor,
            bottomBarColor: bottomBarColor,
            bottomText: bottomText,
            pickUpTimings: pickUpTimings,
          });

          return true;
        });
        this.setState({ storeId, loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  menuChange = () => {
    const { apiDetails } = this.state;
    const md5 = require("md5");
    const uri = "api/mobile/getMenu";

    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);
    const storeId = localStorage.getItem("storeId");
    const Request_Body = JSON.stringify({ store_id: `${storeId}` });
    const sign = md5(
      apiDetails.appKey +
        apiDetails.appSecret +
        uri +
        Request_Body +
        secondsSinceEpoch
    ).toString();

    const config = {
      method: "post",
      url: `${apiDetails.apiDomain}/`.concat(`${uri}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${apiDetails.tenantID}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${apiDetails.appKey}`,
        "Serial-Number": `${apiDetails.deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: Request_Body,
    };
    axios(config)
      .then((response) => {
        const menus = response.data.menu;
        menus.sort(function (a, b) {
          return a.sort_sn > b.sort_sn ? 1 : b.sort_sn > a.sort_sn ? -1 : 0;
        });
        localStorage.setItem("Category_Id", menus[0].id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onDineInClick = () => {
    const { storeId, apiDetails } = this.state;
    this.menuChange();
    const md5 = require("md5");
    const uri = "api/mobile/getActivityComboWithPrice";
    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);
    const Request_Body = JSON.stringify({ store_id: `${storeId}` });
    const sign = md5(
      apiDetails.appKey +
        apiDetails.appSecret +
        uri +
        Request_Body +
        secondsSinceEpoch
    ).toString();

    const config = {
      method: "post",
      url: `${apiDetails.apiDomain}/`.concat(`${uri}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${apiDetails.tenantID}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${apiDetails.appKey}`,
        "Serial-Number": `${apiDetails.deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: Request_Body,
    };

    axios(config)
      .then((response) => {
        const comboInfo = response.data.data;
        localStorage.setItem("comboIdentifyItems", JSON.stringify(comboInfo));
      })
      .catch((error) => {
        console.log(error);
      });

    const md51 = require("md5");
    const uri1 = "api/mobile/getStoreComboProduct";
    const now1 = new Date();
    const secondsSinceEpoch1 = Math.round(now1.getTime() / 1000);
    const Request_Body1 = JSON.stringify({ store_id: `${storeId}` });
    const sign1 = md51(
      apiDetails.appKey +
        apiDetails.appSecret +
        uri1 +
        Request_Body1 +
        secondsSinceEpoch1
    ).toString();

    const config1 = {
      method: "post",
      url: `${apiDetails.apiDomain}/`.concat(`${uri1}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${apiDetails.tenantID}`,
        time: `${secondsSinceEpoch1}`,
        sign: `${sign1}`,
        appkey: `${apiDetails.appKey}`,
        "Serial-Number": `${apiDetails.deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: Request_Body1,
    };

    axios(config1)
      .then((response) => {
        const drinksForCombo = response.data.products;
        localStorage.setItem("drinksForCombo", JSON.stringify(drinksForCombo));
      })
      .catch((error) => {
        console.log(error);
      });

    const { history } = this.props;
    history.push("/thanks");
  };

  render() {
    const {
      landingInfo,
      loading,
      storeAddress,
      topBarColor,
      bottomBarColor,
      bottomText,
      pickUpTimings,
    } = this.state;
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };

    const intViewportWidth = window.innerWidth;
    const intFrameHeightFor = window.innerHeight / 1.59;
    const antIcon = <SyncOutlined spin />;
    return (
      <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{ position: "relative", top: "20em", color: "#f35d5d" }}
        size="large"
      >
        <Row
          style={{
            textAlign: "center",
            backgroundColor: "#fff",
          }}
        >
          <Col {...responsiveDesignForColumn} span={24}>
            <img
              src={landingInfo.Logo}
              alt=""
              style={{ borderRadius: "0px", width: "55%", height: "94%" }}
              onClick={this.onDineInClick}
            />
          </Col>
        </Row>
        <Row
          style={{
            width: `${intViewportWidth}px`,
            position: "fixed",
            top: "12.9%",
          }}
        >
          <Col {...responsiveDesignForColumn}>
            <Card
              style={{
                backgroundColor: `${topBarColor}`,
                position: "relative",
                border: "none",
              }}
            ></Card>
          </Col>
        </Row>
        <Row
          style={{
            textAlign: "center",
            backgroundColor: "#fff",
            position: "fixed",
            top: "14.5%",
            width: `${intViewportWidth}px`,
          }}
        >
          <Col {...responsiveDesignForColumn}>
            {/* <Row>
              <Col {...responsiveDesignForColumn}>
                <b
                  style={{
                    fontSize: "14px",
                    color: "#F35D5D",
                    paddingLeft: "5%",
                  }}
                >
                  {storeName}&emsp;
                </b>
              </Col>
            </Row> */}

            <Row style={{ paddingTop: "0px" }}>
              <Col
                {...responsiveDesignForColumn}
                style={{ textAlign: "center" }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    color: "black",
                    marginBottom: "1%",
                  }}
                >
                  <img
                    src={location}
                    alt=""
                    style={{ borderRadius: "0px", width: "2.2%" }}
                  />{" "}
                  {storeAddress.street}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <div
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
          }}
        >
          <img
            src={landingInfo.LandingPage}
            alt=""
            style={{
              borderRadius: "0px",
              width: "100%",
              height: `${intFrameHeightFor}px`,
            }}
          />
        </div>
        <Row
          style={{
            position: "fixed",
          }}
        >
          <Col {...responsiveDesignForColumn} style={{ textAlign: "center" }}>
            {pickUpTimings.length === 0 ? (
              <img
                src={landingInfo.StoreClosed}
                alt=""
                style={{ borderRadius: "0px", width: "100%", height: "62%" }}
              />
            ) : (
              <img
                src={landingInfo.OrderNowButton}
                alt=""
                style={{ borderRadius: "0px", width: "90%" }}
                onClick={this.onDineInClick}
              />
            )}
          </Col>
        </Row>
        <Row
          style={{
            width: `${intViewportWidth}px`,
            position: "fixed",
            bottom: 0,
          }}
        >
          <Col {...responsiveDesignForColumn}>
            <Card
              style={{
                backgroundColor: `${bottomBarColor}`,
                position: "relative",
                border: "none",
              }}
            >
              <Row style={{ marginTop: "-12px", height: "0px" }}>
                <Col
                  {...responsiveDesignForColumn}
                  style={{ textAlign: "center" }}
                >
                  <b
                    style={{
                      color: "white",
                      fontWeight: "900",
                      fontSize: "14px",
                    }}
                  >
                    {bottomText}
                  </b>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default Sample;

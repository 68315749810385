import React from "react";
import {
  Row,
  Col,
  Card,
  Spin,
  Divider,
  Form,
  Input,
  Button,
  message,
} from "antd";
import location from "../../../images/Location.svg";
import collect from "../../../images/Collect.svg";
import receipt from "../../../images/Receipt.svg";
import { SyncOutlined } from "@ant-design/icons";
import axios from "axios";

import "antd/dist/antd.css";

class OrderPlacing extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      orderNumber: "",
      orderStatus: "",
      imageForSuccess: "",

      totalAmount: 0.0,
      receiptFlag: false,

      colorForSalesType: "",
      scheduleDate: "",
      storeName: "",
      streetName: "",
      payByCredit: "",
      staffDiscount: {},
      subTotal: 0.0,

      discountFlag: false,
      apiDetails: {},
    };
  }

  componentDidMount = () => {
    const colorForSalesType = JSON.parse(
      localStorage.getItem("colorForSalesType")
    );
    const basket = JSON.parse(localStorage.getItem("basket"));
    const apiDetails = JSON.parse(localStorage.getItem("apiDetails"));
    const scheduleDate = JSON.parse(localStorage.getItem("scheduleDate"));
    let dateAndTime = "";
    if (scheduleDate !== null) {
      dateAndTime = scheduleDate.toString();
      let date = dateAndTime.slice(0, 10);
      let time = dateAndTime.slice(11, 16);
      dateAndTime = date + " " + "|" + " " + time;
    }
    const paymentInfo = JSON.parse(localStorage.getItem("pagerAndPickupFlags"))
      .PayType;

    const discountInfo = JSON.parse(
      localStorage.getItem("pagerAndPickupFlags")
    );
    const staffDiscount = discountInfo.StaffDiscount;

    let payByCredit = "";
    paymentInfo.map((data) => {
      if (data.id === 60) {
        payByCredit = parseInt(data.pay_code);
      }
    });
    const storeDetails = JSON.parse(localStorage.getItem("storeDetails"));
    const storeName = JSON.parse(storeDetails.store_name).cn;
    const streetName = storeDetails.street;

    const discountFlag = JSON.parse(localStorage.getItem("discountFlag"));

    const subTotal = parseFloat(localStorage.getItem("total")).toFixed(2);

    let totalAmount = 0.0;
    if (discountFlag === false) {
      totalAmount = parseFloat(localStorage.getItem("total")).toFixed(2);
    } else {
      totalAmount = parseFloat(localStorage.getItem("amountTotal")).toFixed(2);
    }

    const comboInfo = JSON.parse(localStorage.getItem("comboIdentifyItems"));

    let discountInfos = {};
    let discountedAmount = 0.0;
    if (discountFlag === true) {
      discountedAmount = parseFloat(
        localStorage.getItem("discountedAmount")
      ).toFixed(2);
      comboInfo.map((data) => {
        if (data.activity_sn === "2003") {
          discountInfos = data;
        }
      });
    }

    const successResponse = JSON.parse(localStorage.getItem("successResponse"));
    const orderMessages = JSON.parse(localStorage.getItem("storeMessages"));
    const imageInfo = JSON.parse(localStorage.getItem("imagesForMessages"));
    let orderStatus = "";
    let imageForSuccess = "";
    let order_number = successResponse.order_number;
    if (
      order_number === undefined ||
      order_number === null ||
      order_number === ""
    ) {
      order_number = successResponse.cloud_order_number;
      orderStatus = orderMessages.EFT.POSFailed;
      imageForSuccess = imageInfo.EFTPaymentSuccessOrderFailed;
    } else {
      order_number = successResponse.order_number;
      orderStatus = orderMessages.EFT.Success;
      imageForSuccess = imageInfo.EFTPaymentSuccess;
    }

    this.setState({
      totalAmount: totalAmount,
      colorForSalesType: colorForSalesType,
      scheduleDate: dateAndTime,
      storeName: storeName,
      streetName: streetName,
      staffDiscount: staffDiscount,
      subTotal: subTotal,
      discountedAmount: discountedAmount,
      discountFlag: discountFlag,
      apiDetails: apiDetails,
      orderNumber: order_number,
      orderStatus: orderStatus,
      imageForSuccess: imageForSuccess,
      payByCredit: payByCredit,
      apiDetails: apiDetails,
      basket: basket,
    });
  };

  onReceipt = (values) => {
    const { payByCredit, apiDetails } = this.state;
    this.setState({ receiptFlag: true, values: values });
    const basket = JSON.parse(localStorage.getItem("basket"));
    const discountFlag = JSON.parse(localStorage.getItem("discountFlag"));

    let totalAmount = 0.0;
    if (discountFlag === false) {
      totalAmount = parseFloat(localStorage.getItem("total")).toFixed(2);
    } else {
      totalAmount = parseFloat(localStorage.getItem("amountTotal")).toFixed(2);
    }

    const comboInfo = JSON.parse(localStorage.getItem("comboIdentifyItems"));

    let discountInfos = {};
    let discountedAmount = 0.0;
    if (discountFlag === true) {
      discountedAmount = parseFloat(
        localStorage.getItem("discountedAmount")
      ).toFixed(2);
      comboInfo.map((data) => {
        if (data.activity_sn === "2003") {
          discountInfos = data;
        }
      });
    }

    const pagerValue = JSON.parse(localStorage.getItem("pagerValue"));
    const scheduleDate = JSON.parse(localStorage.getItem("scheduleDate"));
    const saleTypeNum = parseFloat(localStorage.getItem("saleTypeNum"));
    const storeId = parseFloat(localStorage.getItem("storeId"));

    const cloud_order_number = JSON.parse(
      localStorage.getItem("cloud_order_number")
    );
    const userMailId = values.email;

    const userName = values.username;
    const md5 = require("md5");
    const uri = "api/mobile/sendEmailReceipt";

    const sendingItems = [];
    const asyncBasket = [];

    basket.map((data) => {
      if (data.comboSno && data.SubProduct) {
        data.SubProduct.map((value) => {
          value.Modifiers.map((modifier) => {
            modifier.quantity = data.Quantity;
          });

          value.quantity = data.Quantity;
        });
        asyncBasket.push({
          MainProduct: `${data.comboSno}`,
          quantity: data.Quantity,
          SubProducts: {
            SubProduct: data.SubProduct,
          },
        });
      } else {
        if (data.Modifiers) {
          data.Modifiers.map((value) => {
            value.quantity = data.Quantity;
          });
          sendingItems.push({
            Modifiers: data.Modifiers,
            prodNum: `${data.Prod_IDFor}`,
            quantity: data.Quantity,
          });
        } else {
          sendingItems.push({
            Modifiers: [],
            prodNum: `${data.Prod_IDFor}`,
            quantity: data.Quantity,
          });
        }
      }
    });

    if (discountFlag === true) {
      sendingItems.push({
        couponNum: `${discountInfos.discount_sn}`,
        quantity: 1,
        discount_num: `${discountInfos.discount_num}`,
        discount_amt: `${discountedAmount}`,
        discount_name: `${discountInfos.activity_name}`,
      });
    }

    const obj_PixelPoint_OrderBefore = {
      Request: {
        Transaction: {
          ComboItems: {
            ComboItem: asyncBasket,
          },
          Payments: {
            Payment: [
              {
                methodNumber: payByCredit,
                tender: totalAmount,
                status: "paid",
              },
            ],
          },
          SingleItems: {
            SingleItem: sendingItems,
          },
          label: pagerValue,
          ScheduleDate: `${scheduleDate}`,
          saleTypeNum: saleTypeNum,
          storeId: storeId,
          emailTo: `${userMailId}`,
          name: `${userName}`,
          phone: "",
          SaaS_order_number: `${cloud_order_number}`,
        },
        requestType: 15,
      },
    };

    const Request_Body = JSON.stringify(obj_PixelPoint_OrderBefore);

    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    const sign = md5(
      apiDetails.appKey +
        apiDetails.appSecret +
        uri +
        Request_Body +
        secondsSinceEpoch
    ).toString();

    const config = {
      method: "post",
      url: `${apiDetails.apiDomain}/`.concat(`${uri}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${apiDetails.tenantID}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${apiDetails.appKey}`,
        "Serial-Number": `${apiDetails.deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: Request_Body,
    };
    axios(config)
      .then((response) => {})
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  onGetBackToHome = () => {
    localStorage.setItem("basketQunatityItems", JSON.stringify([]));
    localStorage.setItem("basket", JSON.stringify([]));
    localStorage.setItem("total", 0);
    localStorage.setItem("amountTotal", 0);
    localStorage.setItem("itemsQuantity", 0);
    localStorage.setItem("placingDetails", JSON.stringify([]));
    localStorage.setItem("allProductsData", JSON.stringify([]));
    localStorage.setItem("orderDetails", JSON.stringify({}));
    localStorage.setItem("userValues", JSON.stringify({}));
    localStorage.setItem("pagerValue", JSON.stringify(""));
    localStorage.setItem("discountFlag", JSON.stringify(false));
    localStorage.setItem("discountedAmount", 0);
    localStorage.setItem("resultIndicator1", JSON.stringify(""));
    localStorage.setItem("successIndicator", JSON.stringify([]));

    localStorage.setItem("scheduleDate", JSON.stringify(""));
    localStorage.removeItem("Category_Id");
    const { history } = this.props;
    history.push("../../thanks");
  };

  render() {
    const {
      orderStatus,
      loading,
      orderNumber,
      imageForSuccess,
      basket,
      totalAmount,
      receiptFlag,
      values,
      colorForSalesType,
      scheduleDate,
      storeName,
      streetName,
      staffDiscount,
      subTotal,
      discountedAmount,
      discountFlag,
    } = this.state;
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };
    const responsiveDesignForSkip = {
      xxl: 18,
      xl: 18,
      lg: 18,
      xs: 18,
      sm: 18,
      md: 18,
    };
    const responsiveDesignForQuantity = {
      xxl: 2,
      xl: 2,
      lg: 2,
      xs: 2,
      sm: 2,
      md: 2,
    };
    const responsiveDesignAddons = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };

    const responsiveDesignPager = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };

    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };
    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };

    const responsiveDesignDish = {
      xxl: 6,
      xl: 6,
      lg: 6,
      xs: 6,
      sm: 6,
      md: 6,
    };

    const pagerValue = JSON.parse(localStorage.getItem("pagerValue"));

    const intFrameHeight = window.innerHeight;
    const intViewportWidth = window.innerWidth;
    const antIcon = <SyncOutlined spin />;
    return (
      <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{ position: "relative", top: "25em", color: "lightGreen" }}
        size="large"
      >
        <div
          style={{
            margin: "0px",
            height: `${intFrameHeight}px`,

            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",

            backgroundColor: "#fff",
          }}
        >
          <Row style={{ paddingTop: "40px" }}>
            <Col {...responsiveDesignForColumn} style={{ textAlign: "center" }}>
              <img
                style={{
                  fontWeight: "700",
                  width: "50%",

                  marginTop: "-5px",
                }}
                src={imageForSuccess}
                alt=""
              />
            </Col>
          </Row>

          <Row style={{ backgroundColor: "#fff" }}>
            <Col
              {...responsiveDesignForColumn}
              style={{
                paddingTop: "25px",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingBottom: "8px",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: "17px",

                  color: "black",
                }}
              >
                {orderStatus}
              </p>
            </Col>
            <Col
              {...responsiveDesignForColumn}
              style={{ textAlign: "center", paddingBottom: "8px" }}
            >
              <b
                style={{
                  fontSize: "24px",

                  color: "black",
                }}
              >
                Your Order Number
              </b>
            </Col>

            <br />

            <Col
              {...responsiveDesignForColumn}
              style={{ textAlign: "center", paddingTop: "12px" }}
            >
              <b
                style={{
                  fontSize: "30px",

                  color: "black",
                }}
              >
                {orderNumber}
              </b>
            </Col>
            {pagerValue === "" ? (
              <Col
                {...responsiveDesignForColumn}
                style={{ display: "none", paddingTop: "10px" }}
              >
                <b
                  style={{
                    fontSize: "30px",

                    color: "black",
                  }}
                >
                  {pagerValue}
                </b>
              </Col>
            ) : (
              <Col
                {...responsiveDesignForColumn}
                style={{ textAlign: "center", paddingTop: "10px" }}
              >
                <Row style={{ backgroundColor: "#fff" }}>
                  <Col {...responsiveDesignPager}>
                    <b
                      style={{
                        fontSize: "20px",
                        color: "black",
                        marginLeft: "25%",
                      }}
                    >
                      Pager Number:
                    </b>
                  </Col>
                  <Col
                    {...responsiveDesignAddons}
                    style={{ textAlign: "left" }}
                  >
                    <b
                      style={{
                        fontSize: "20px",
                        color: "black",
                        marginLeft: "-8%",
                      }}
                    >
                      {pagerValue}
                    </b>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <br />
          {colorForSalesType === "#000000" ? (
            <div style={{ backgroundColor: "#fff" }}>
              {" "}
              {receiptFlag === true ? (
                <div>
                  <Row>
                    <Col
                      {...responsiveDesignForColumn}
                      style={{ textAlign: "center" }}
                    >
                      <p
                        style={{
                          fontSize: "15px",

                          color: "#512C1D",
                          fontWeight: "550",
                        }}
                      >
                        Thank you {values.username}!
                      </p>
                    </Col>
                    <Col
                      {...responsiveDesignForColumn}
                      style={{ textAlign: "center" }}
                    >
                      <p
                        style={{
                          fontSize: "15px",

                          color: "#512C1D",
                          fontWeight: "550",
                        }}
                      >
                        Your receipt has been sent to{" "}
                        {
                          <a
                            style={{
                              color: "deepskyblue",
                              borderBottom: "1px solid deepskyblue",
                            }}
                          >
                            {values.email}
                          </a>
                        }
                        !
                      </p>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div style={{ padding: "25px", backgroundColor: "#fff" }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      color: "black",
                    }}
                  >
                    If you wish to receive the receipt by email, please enter
                    your name and email below:{" "}
                  </p>
                  <Form
                    ref={this.formRef}
                    name="control-ref"
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={this.onReceipt}
                  >
                    <Form.Item
                      label="Name"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item
                      label="E-mail Address"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail Address!",
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item
                      style={{ paddingTop: "15px", textAlign: "center" }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#F35D5D",
                          position: "relative",
                          width: "60%",
                          height: "46px",
                          border: "1px solid #F35D5D",
                        }}
                        htmlType="submit"
                      >
                        <b style={{ color: "white", fontSize: "18px" }}>
                          SEND RECEIPT
                        </b>
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              )}
            </div>
          ) : (
            <div style={{ backgroundColor: "#fff" }}>
              <div
                style={{
                  borderBottom: "1px solid #292929",
                  borderTop: "1px solid #292929",
                  paddingRight: "15px",
                  paddingBottom: "15px",
                  paddingTop: "15px",
                  backgroundColor: "#fff",
                }}
              >
                <Row>
                  <Col
                    {...responsiveDesignForColumn}
                    style={{ textAlign: "center" }}
                  >
                    <p
                      style={{
                        fontSize: "18px",

                        color: "#292929",
                        fontWeight: "550",
                      }}
                    >
                      How to pick-up your order?
                    </p>
                  </Col>
                  <Col
                    {...responsiveDesignForColumn}
                    style={{ textAlign: "center" }}
                  >
                    <Row>
                      <Col {...responsiveDesignAddons}>
                        <Row>
                          <Col
                            {...responsiveDesignForColumn}
                            style={{ textAlign: "center" }}
                          >
                            <img
                              style={{
                                width: "50%",
                                height: "90%",
                              }}
                              src={location}
                              alt=""
                            />
                          </Col>
                          <Col
                            {...responsiveDesignForColumn}
                            style={{ textAlign: "center" }}
                          >
                            <p
                              style={{
                                fontSize: "14px",

                                color: "#292929",
                                fontWeight: "550",
                              }}
                            >
                              Go to the Restaurant
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col {...responsiveDesignAddons}>
                        <Row>
                          <Col
                            {...responsiveDesignForColumn}
                            style={{ textAlign: "center" }}
                          >
                            <img
                              style={{
                                width: "50%",
                                height: "90%",
                              }}
                              src={receipt}
                              alt=""
                            />
                          </Col>
                          <Col
                            {...responsiveDesignForColumn}
                            style={{ textAlign: "center" }}
                          >
                            <p
                              style={{
                                fontSize: "14px",

                                color: "#292929",
                                fontWeight: "550",
                              }}
                            >
                              Show your order number
                            </p>
                          </Col>
                        </Row>
                      </Col>

                      <Col {...responsiveDesignAddons}>
                        <Row>
                          <Col
                            {...responsiveDesignForColumn}
                            style={{ textAlign: "center" }}
                          >
                            <img
                              style={{
                                width: "50%",
                                height: "90%",
                              }}
                              src={collect}
                              alt=""
                            />
                          </Col>
                          <Col
                            {...responsiveDesignForColumn}
                            style={{ textAlign: "center" }}
                          >
                            <p
                              style={{
                                fontSize: "14px",

                                color: "#292929",
                                fontWeight: "550",
                              }}
                            >
                              Collect your food and enjoy!
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "9px" }}>
                <Row>
                  <Col {...responsiveDesignForColumn}>
                    <p
                      style={{
                        fontSize: "14px",

                        color: "#292929",
                        fontWeight: "550",
                      }}
                    >
                      You ordered from:&nbsp;
                      <a
                        style={{
                          color: "black",
                          opacity: "60%",
                        }}
                      >
                        {storeName}
                      </a>
                    </p>
                  </Col>
                  <Col {...responsiveDesignForColumn}>
                    <p
                      style={{
                        fontSize: "14px",

                        color: "#292929",
                        fontWeight: "550",
                      }}
                    >
                      Restaurant address:&nbsp;
                      <a
                        style={{
                          color: "black",
                          opacity: "60%",
                        }}
                      >
                        {streetName}
                      </a>
                    </p>
                  </Col>
                  <Col {...responsiveDesignForColumn}>
                    <p
                      style={{
                        fontSize: "14px",

                        color: "#292929",
                        fontWeight: "550",
                      }}
                    >
                      Pick-Up Date/Time:&nbsp;
                      <a
                        style={{
                          color: "black",
                          opacity: "60%",
                        }}
                      >
                        {scheduleDate}
                      </a>
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          )}

          <Row style={{ backgroundColor: "#fff", paddingBottom: "100px" }}>
            <Col
              {...responsiveDesignForColumn}
              style={{
                paddingLeft: "7px",
                paddingRight: "7px",
                paddingTop: "7px",
              }}
            >
              <Card
                style={{
                  backgroundColor: "#fff",
                  position: "relative",
                  boxShadow: " 0px 2px 6px -3px",
                  paddingBottom: "30px",
                }}
              >
                <Row
                  style={{
                    marginBottom: "-8px",
                    textAlign: "center",
                    fontSize: "16px",
                    display: "block",
                  }}
                >
                  <Col>
                    <b>ORDER SUMMARY</b>
                  </Col>
                </Row>
                <Divider style={{ margin: "20px" }} />
                <div>
                  {basket !== undefined ? (
                    <div>
                      {basket.map((data) => {
                        return (
                          <Row style={{ marginBottom: "-8px" }}>
                            <Col {...responsiveDesignForSkip}>
                              <b style={{ color: "#512C1D" }}>{data.Name}</b>
                            </Col>
                            <Col {...responsiveDesignForQuantity}>
                              <b style={{ color: "#512C1D" }}>
                                x{data.Quantity}{" "}
                              </b>
                            </Col>
                            <Col {...responsiveDesignForImage}>
                              <b style={{ float: "right", color: "#512C1D" }}>
                                $
                                {data.Customizable === 0 ||
                                data.listPrice === 0.0
                                  ? parseFloat(data.Price).toFixed(2)
                                  : parseFloat(data.listPrice).toFixed(2)}
                              </b>
                            </Col>
                            <Col {...responsiveDesignForColumn}>
                              {data.selectedAddons ? (
                                <div
                                  style={{
                                    display: "flex",
                                    maxWidth: `${intViewportWidth}`,
                                    overflowX: "scroll",
                                  }}
                                >
                                  {data.selectedAddons.map((value) => {
                                    return (
                                      <Col {...responsiveDesignAddons}>
                                        <ul style={{ marginLeft: "-25px" }}>
                                          <li
                                            style={{
                                              color: "#292929",
                                              opacity: "80%",
                                            }}
                                          >
                                            {" "}
                                            {
                                              JSON.parse(value.att_val_name).cn
                                            }{" "}
                                            &ensp; &nbsp;
                                          </li>
                                        </ul>
                                      </Col>
                                    );
                                  })}
                                </div>
                              ) : (
                                <Col lg={24}></Col>
                              )}
                            </Col>
                            &ensp;
                          </Row>
                        );
                      })}
                    </div>
                  ) : (
                    <div>mamatha</div>
                  )}
                </div>
                <Divider style={{ margin: "15px" }} />
                <Row
                  style={{
                    fontSize: "16px",
                  }}
                >
                  <Col {...responsiveDesignForSkip}>
                    <b style={{ color: "#512C1D" }}>SUB-TOTAL</b>
                  </Col>
                  <Col {...responsiveDesignDish}>
                    <b style={{ color: "#512C1D", float: "right" }}>
                      ${subTotal}
                    </b>
                  </Col>
                </Row>
                {discountFlag === true ? (
                  <Row
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    <Col {...responsiveDesignForSkip}>
                      <b style={{ color: "#512C1D" }}>
                        {" "}
                        {staffDiscount.Message3}
                      </b>
                    </Col>
                    <Col {...responsiveDesignDish}>
                      <b style={{ color: "#512C1D", float: "right" }}>
                        - ${discountedAmount}
                      </b>
                    </Col>
                  </Row>
                ) : (
                  <Row
                    style={{
                      fontSize: "16px",
                      display: "none",
                    }}
                  ></Row>
                )}
                <Divider style={{ margin: "5px" }} />
                <Row
                  style={{
                    fontSize: "16px",
                    marginBottom: "-35px",
                  }}
                >
                  <Col {...responsiveDesignForSkip}>
                    <b style={{ color: "#512C1D" }}>FINAL-TOTAL</b>
                  </Col>
                  <Col {...responsiveDesignDish}>
                    <b style={{ color: "#512C1D", float: "right" }}>
                      ${totalAmount}
                    </b>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row
            style={{
              paddingTop: "9px",
              paddingBottom: "8px",
              width: `${intViewportWidth}px`,
              position: "fixed",
              bottom: 0,
            }}
          >
            <Col
              {...responsiveDesignForColumn}
              style={{
                paddingLeft: "7px",
                paddingRight: "7px",
                paddingTop: "7px",
              }}
            >
              <Card
                style={{
                  backgroundColor: "#F35D5D",
                  position: "relative",
                }}
                onClick={this.onGetBackToHome}
              >
                <Row style={{ margin: "0px" }}>
                  <Col
                    {...responsiveDesignForColumn}
                    style={{ textAlign: "center" }}
                  >
                    <b
                      style={{
                        color: "white",
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      BACK TO MAIN MENU
                    </b>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}

export default OrderPlacing;

import React from "react";
import { Switch, Route } from "react-router-dom";
import Header from "./components/common/Header";
import Sample from "./components/Sample";

import List from "./components/List";
import NotFound from "./components/NotFound";
import Thanks from "./components/Thanks";
import TermsAndConditions from "./components/termsAndConditions";
import RefundAndCancel from "./components/refundAndCancel";
import Privacy from "./components/privacy";
import ContactPage from "./components/contactPage";
import Extra from "./components/Extra";
import Login from "./components/Login";
import Register from "./components/Register";
import TrackOrder from "./components/TrackOrder";
import Invoice from "./components/Invoice";
// import Checkout from './components/Checkout';
import Detail from "./components/Detail";
import InsideCart from "./components/viewCart/insideCart/index";
import Payment from "./components/viewCart/payment/index";
import PickUp from "./components/viewCart/pickUp/index";
import SalesType from "./components/viewCart/salesType/index";
import OrderPlacing from "./components/viewCart/orderPlacing/index";
import OrderPlacingAtCounter from "./components/viewCart/orderAtCounter/index";
import AddOnCart from "./components/viewCart/addOnCart/index";
import ComboItem from "./components/viewCart/comboItems/index";
import AdditionalCart from "./components/viewCart/additionalCart/index";
import DrinksModifier from "./components/viewCart/drinkModifier/index";
import Processing from "./components/viewCart/processing/index";
import ErrorInPlacing from "./components/viewCart/errorPage/index";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-select2-wrapper/css/select2.css";
import "./App.css";
import Landing from "./components/Landing";

class App extends React.Component {
  render() {
    return (
      <>
        {this.props.location.pathname !== "/locate/:id" &&
        this.props.location.pathname !== "/" &&
        this.props.location.pathname !== "/sample" &&
        this.props.location.pathname !== "/termsAndConditions" &&
        this.props.location.pathname !== "/refundAndCancel" &&
        this.props.location.pathname !== "/privacy" &&
        this.props.location.pathname !== "/contactPage" &&
        this.props.location.pathname !== "/viewCart/insideCart/index.js" &&
        this.props.location.pathname !== "/viewCart/payment/index.js" &&
        this.props.location.pathname !== "/viewCart/pickUp/index.js" &&
        this.props.location.pathname !== "/viewCart/salesType/index.js" &&
        this.props.location.pathname !== "/viewCart/orderPlacing/index.js" &&
        this.props.location.pathname !== "/viewCart/orderAtCounter/index.js" &&
        this.props.location.pathname !== "/viewCart/addOnCart/index.js" &&
        this.props.location.pathname !== "/viewCart/errorPage/index.js" &&
        this.props.location.pathname !== "/viewCart/comboItems/index.js" &&
        this.props.location.pathname !== "/viewCart/additionalCart/index.js" &&
        this.props.location.pathname !== "/viewCart/drinkModifier/index.js" &&
        this.props.location.pathname !== "/viewCart/processing/index.js" ? (
          <div>
            <Header />
          </div>
        ) : (
          <Switch>
            <Route path="/" exact component={Sample} />
            <Route path="/locate/:id" exact component={Landing} />
            <Route path="/sample" exact component={Sample} />
            <Route
              path="/termsAndConditions"
              exact
              component={TermsAndConditions}
            />
            <Route path="/refundAndCancel" exact component={RefundAndCancel} />
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/contactPage" exact component={ContactPage} />
            <Route
              path="/viewCart/insideCart/index.js"
              exact
              component={InsideCart}
            />
            <Route
              path="/viewCart/payment/index.js"
              exact
              component={Payment}
            />
            <Route path="/viewCart/pickUp/index.js" exact component={PickUp} />
            <Route
              path="/viewCart/salesType/index.js"
              exact
              component={SalesType}
            />
            <Route
              path="/viewCart/orderPlacing/index.js"
              exact
              component={OrderPlacing}
            />
            <Route
              path="/viewCart/orderAtCounter/index.js"
              exact
              component={OrderPlacingAtCounter}
            />
            <Route
              path="/viewCart/addOnCart/index.js"
              exact
              component={AddOnCart}
            />
            <Route
              path="/viewCart/errorPage/index.js"
              exact
              component={ErrorInPlacing}
            />
            <Route
              path="/viewCart/comboItems/index.js"
              exact
              component={ComboItem}
            />
            <Route
              path="/viewCart/additionalCart/index.js"
              exact
              component={AdditionalCart}
            />
            <Route
              path="/viewCart/drinkModifier/index.js"
              exact
              component={DrinksModifier}
            />
            <Route
              path="/viewCart/processing/index.js"
              exact
              component={Processing}
            />
            <Route exact component={NotFound} />
          </Switch>
        )}
        <Switch>
          <Route path="/listing" exact component={List} />
          <Route path="/404" exact component={NotFound} />
          <Route path="/extra" exact component={Extra} />
          <Route path="/login" exact component={Login} />
          <Route path="/register" exact component={Register} />
          <Route path="/track-order" exact component={TrackOrder} />
          <Route path="/invoice" exact component={Invoice} />
          <Route path="/thanks" exact component={Thanks} />
          <Route path="/detail" exact component={Detail} />
        </Switch>
      </>
    );
  }
}

export default App;

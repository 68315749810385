import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
// import { URL } from "../config";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

class ProductBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryItems: [],
    };
  }

  /* componentDidMount() {
    this.categoryList();
  } */

  categoryProducts = (key) => {
    const { storeDispatch, storeDispatchFor } = this.props;
    // const { allProductsData } = this.state;
    this.props.setCategoryId(key);
    let allProductsData = [];
    axios({
      method: "post",
      url: URL.concat("allProducts"),
      data: { key: key },
    }).then((response) => {
      // console.log("response========>>>>>>>>>", response);
      allProductsData = response.data;
      // console.log("allProducts=======>>>>>>>", allProductsData);
      storeDispatch({ storeDispatch: allProductsData });
      storeDispatchFor({ key: key });
      this.setState({ allProductsData: allProductsData });
      localStorage.setItem("allProductsData", JSON.stringify(allProductsData));
    });
  };

  render() {
    const { categoryItems } = this.state;
    // console.log("data===============>>>>>>>>>>>>>>", categoryItems);

    return (
      <div
        className={this.props.boxClass}
        onClick={() => this.categoryProducts(this.props.catId)}
        // style={{ width: "auto" }}
      >
        <Link to={this.props.linkUrl}>
          <Image
            src={this.props.image}
            className={this.props.imageClass}
            alt={this.props.imageAlt}
          />
          {this.props.title ? <h6>{this.props.title}</h6> : ""}
          {this.props.counting ? <p>{this.props.counting}</p> : ""}
        </Link>
      </div>
    );
  }
}

ProductBox.propTypes = {
  linkUrl: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  imageAlt: PropTypes.string,
  boxClass: PropTypes.string,
  title: PropTypes.string,
  counting: PropTypes.string,
};
ProductBox.defaultProps = {
  imageAlt: "",
  image: "",
  imageClass: "",
  linkUrl: "",
  boxClass: "products-box",
  title: "",
  counting: "",
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeDispatch: (data) => {
      // console.log("data=========>>>>>>>>>>", data);
      dispatch({ type: "SELECTED_CATGORY_DATA", payload: data });
    },
    storeDispatchFor: (dataa) => {
      // console.log("dataaa=========>>>>>>>>>>", dataa);
      dispatch({ type: "SELECTED_CAT_ID", payload: dataa });
    },
  };
};

const mapStateToProps = ({ CategoryView }) => {
  // console.log("CategoryView=======>>>>>>>>>", CategoryView);
  return {
    CategoryView: CategoryView.selectedCategoryData.storeDispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductBox);

import React from "react";
import { Row, Col, Card, message, Spin, Button, Checkbox } from "antd";
import { returnUrl } from "../../config";
import axios from "axios";
// import Checkout from "https://test-gateway.mastercard.com/checkout/version/59/checkout.js";

import { SyncOutlined } from "@ant-design/icons";
import masterCard from "../../../images/vias_Master.jpeg";
import payAtCounter from "../../../images/payAtCounter.png";
import Cash from "../../../images/Cash.png";
import NETS_Contact from "../../../images/NETS_Contact.png";

import NETS_QRmark from "../../../images/NETS_QRmark.png";
import payNow from "../../../images/payNow.png";
import grabPay from "../../../images/grabPay.png";
import Vouchers from "../../../images/Vouchers.jpg";
import RSVPLogo from "../../../images/RSVPLogo.jpg";

import "antd/dist/antd.css";

import { LeftOutlined } from "@ant-design/icons";

// const Checkout = window.Checkout;

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basket: [],
      paymentFlag: false,
      highLightFlag: false,
      highLightFlagForCounter: false,
      loading: false,
      pagerValue: "",
      color: "lightgray",
      userValues: {},
      payAtCounter: "",
      payByCredit: "",
      amountTotal: "",
      staffDiscount: {},
      discountFlag: false,
      discountMessage: "",
      discountInfos: {},
      discountedAmount: 0.0,
      apiDetails: {},
    };
  }

  componentDidMount = () => {
    const comboInfo = JSON.parse(localStorage.getItem("comboIdentifyItems"));
    const apiDetails = JSON.parse(localStorage.getItem("apiDetails"));

    localStorage.setItem("discountFlag", JSON.stringify(false));
    const totalAmount = localStorage.getItem("total");
    const amountTotal = parseFloat(totalAmount);
    const pagerValue = JSON.parse(localStorage.getItem("pagerValue"));
    const userValues = JSON.parse(localStorage.getItem("userValues"));
    const paymentInfo = JSON.parse(localStorage.getItem("pagerAndPickupFlags"))
      .PayType;
    const discountInfo = JSON.parse(
      localStorage.getItem("pagerAndPickupFlags")
    );
    const staffDiscount = discountInfo.StaffDiscount;

    const colorForSalesType = JSON.parse(
      localStorage.getItem("colorForSalesType")
    );
    let payAtCounter = "";
    let payByCredit = "";
    paymentInfo.map((data) => {
      if (data.id === 23) {
        payAtCounter = parseInt(data.pay_code);
      } else {
        payByCredit = parseInt(data.pay_code);
      }
    });

    if (staffDiscount.Enabled === true) {
      comboInfo.map((data) => {
        if (data.activity_sn === "2003") {
          this.setState({ discountInfos: data });
        }
      });
    }

    this.setState({
      pagerValue: pagerValue,
      color: colorForSalesType,
      userValues: userValues,
      paymentInfo: paymentInfo,
      payAtCounter: payAtCounter,
      payByCredit: payByCredit,
      amountTotal: amountTotal,
      staffDiscount: staffDiscount,
      apiDetails: apiDetails,
    });
  };

  onBackToCart = () => {
    const { history } = this.props;
    const { color } = this.state;
    if (color === "lightgray") {
      history.push("../pickUp/index.js");
    } else {
      history.push("../salesType/index.js");
    }
    localStorage.setItem("discountedAmount", 0);
  };

  onDisplayPayment = () => {
    this.setState({
      highLightFlagForCounter: false,
      paymentFlag: true,
    });
  };

  onPayAtCounter = () => {
    const { color } = this.state;
    if (color === "lightgray") {
      this.setState({
        paymentFlag: false,
        highLightFlag: true,
        highLightFlagForCounter: false,
      });
    } else if (color === "#000000") {
      this.setState({
        paymentFlag: false,
        highLightFlag: true,
        highLightFlagForCounter: true,

        userValues: {},
      });
    }
  };

  onFromPayAtCounter = () => {
    const { history } = this.props;
    const {
      pagerValue,
      payAtCounter,
      discountFlag,
      discountedAmount,
      discountInfos,
      apiDetails,
    } = this.state;

    this.setState({ loading: true });
    const basket = JSON.parse(localStorage.getItem("basket"));
    let totalAmount = 0.0;
    if (discountFlag === false) {
      totalAmount = localStorage.getItem("total");
    } else {
      totalAmount = localStorage.getItem("amountTotal");
    }

    const storeId = parseFloat(localStorage.getItem("storeId"));
    const saleTypeNum = parseFloat(localStorage.getItem("saleTypeNum"));

    const md5 = require("md5");
    const uri = "api/mobile/syncMobileOrder2";

    const sendingItems = [];
    const asyncBasket = [];

    basket.map((data) => {
      if (data.comboSno && data.SubProduct) {
        data.SubProduct.map((value) => {
          value.Modifiers.map((modifier) => {
            modifier.quantity = data.Quantity;
          });

          value.quantity = data.Quantity;
        });

        asyncBasket.push({
          MainProduct: `${data.comboSno}`,
          quantity: data.Quantity,
          SubProducts: {
            SubProduct: data.SubProduct,
          },
        });
      } else {
        if (data.Modifiers) {
          data.Modifiers.map((value) => {
            value.quantity = data.Quantity;
          });
          sendingItems.push({
            Modifiers: data.Modifiers,
            prodNum: `${data.Prod_IDFor}`,
            quantity: data.Quantity,
          });
        } else {
          sendingItems.push({
            Modifiers: [],
            prodNum: `${data.Prod_IDFor}`,
            quantity: data.Quantity,
          });
        }
      }
    });

    if (discountFlag === true) {
      sendingItems.push({
        couponNum: `${discountInfos.discount_sn}`,
        quantity: 1,
        discount_num: `${discountInfos.discount_num}`,
        discount_amt: `${discountedAmount}`,
        discount_name: `${discountInfos.activity_name}`,
      });
    }

    const obj_PixelPoint_OrderBefore = {
      Request: {
        Transaction: {
          ComboItems: {
            ComboItem: asyncBasket,
          },
          Payments: {
            Payment: [
              {
                methodNumber: payAtCounter,
                tender: totalAmount,
                status: "pending",
              },
            ],
          },
          returnUrl: `${returnUrl}`,
          SingleItems: {
            SingleItem: sendingItems,
          },
          label: pagerValue,

          saleTypeNum: saleTypeNum,
          storeId: storeId,
        },
        requestType: 15,
      },
    };

    const Request_Body = JSON.stringify(obj_PixelPoint_OrderBefore);

    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    const sign = md5(
      apiDetails.appKey +
        apiDetails.appSecret +
        uri +
        Request_Body +
        secondsSinceEpoch
    ).toString();

    const config = {
      method: "post",
      url: `${apiDetails.apiDomain}/`.concat(`${uri}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${apiDetails.tenantID}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${apiDetails.appKey}`,
        "Serial-Number": `${apiDetails.deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: Request_Body,
    };
    setTimeout(() => {
      axios(config)
        .then((response) => {
          const orderDetails = response.data;

          localStorage.setItem("orderDetails", JSON.stringify(orderDetails));
          history.push("../orderAtCounter/index.js");
        })
        .catch((error) => {
          message.error(`${error}`);
          this.setState({ loading: false });
        });
    }, 1500);
  };

  onPayFromCard = () => {
    const {
      pagerValue,
      userValues,
      color,
      payByCredit,
      discountFlag,
      discountInfos,
      discountedAmount,
      apiDetails,
    } = this.state;

    this.setState({ loading: true });
    let totalAmount = 0.0;
    if (discountFlag === false) {
      totalAmount = localStorage.getItem("total");
    } else {
      totalAmount = localStorage.getItem("amountTotal");
    }
    const scheduleDateFor = JSON.parse(localStorage.getItem("scheduleDate"));
    let scheduleDate = "";
    if (scheduleDateFor === null) {
      scheduleDate = "";
    } else {
      scheduleDate = JSON.parse(localStorage.getItem("scheduleDate"));
    }
    const storeId = parseFloat(localStorage.getItem("storeId"));
    const saleTypeNum = parseFloat(localStorage.getItem("saleTypeNum"));
    const typeForSalesType = JSON.parse(
      localStorage.getItem("typeForSalesType")
    );
    let userMailId = "";
    let userName = "";
    let userNumber = "";

    if (color === "lightgray") {
      userMailId = userValues.email;
      userName = userValues.username;
      userNumber = userValues.number;
    } else {
      userMailId = "";
      userName = "";
      userNumber = "";
    }

    const basket = JSON.parse(localStorage.getItem("basket"));

    const md5 = require("md5");
    const uri = "api/mobile/syncMobileOrder2";

    const sendingItems = [];
    const asyncBasket = [];

    basket.map((data) => {
      if (data.comboSno && data.SubProduct) {
        data.SubProduct.map((value) => {
          value.Modifiers.map((modifier) => {
            modifier.quantity = data.Quantity;
          });

          value.quantity = data.Quantity;
        });
        asyncBasket.push({
          MainProduct: `${data.comboSno}`,
          SubProducts: {
            SubProduct: data.SubProduct,
          },
        });
      } else {
        if (data.Modifiers) {
          data.Modifiers.map((value) => {
            value.quantity = data.Quantity;
          });
          sendingItems.push({
            Modifiers: data.Modifiers,
            prodNum: `${data.Prod_IDFor}`,
            quantity: data.Quantity,
          });
        } else {
          sendingItems.push({
            Modifiers: [],
            prodNum: `${data.Prod_IDFor}`,
            quantity: data.Quantity,
          });
        }
      }
    });

    if (discountFlag === true) {
      sendingItems.push({
        couponNum: `${discountInfos.discount_sn}`,
        quantity: 1,
        discount_num: `${discountInfos.discount_num}`,
        discount_amt: `${discountedAmount}`,
        discount_name: `${discountInfos.activity_name}`,
      });
    }

    const obj_PixelPoint_OrderBefore = {
      Request: {
        Transaction: {
          ComboItems: {
            ComboItem: asyncBasket,
          },
          Payments: {
            Payment: [
              {
                methodNumber: payByCredit,
                tender: totalAmount,
                status: "pending",
              },
            ],
          },
          returnUrl: `${returnUrl}`,
          SingleItems: {
            SingleItem: sendingItems,
          },
          label: pagerValue,
          ScheduleDate: `${scheduleDate}`,
          saleTypeNum: saleTypeNum,
          storeId: storeId,
          emailTo: `${userMailId}`,
          name: `${userName}`,
          phone: `${userNumber}`,
        },
        requestType: 15,
      },
    };
    const Request_Body = JSON.stringify(obj_PixelPoint_OrderBefore);

    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    const sign = md5(
      apiDetails.appKey +
        apiDetails.appSecret +
        uri +
        Request_Body +
        secondsSinceEpoch
    ).toString();

    let timeOutError = "";
    const config = {
      method: "post",
      url: `${apiDetails.apiDomain}/`.concat(`${uri}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${apiDetails.tenantID}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${apiDetails.appKey}`,
        "Serial-Number": `${apiDetails.deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: Request_Body,
    };

    setTimeout(() => {
      axios(config)
        .then((response) => {
          const generateSession = response.data.sessionID;
          const cloud_order_number = response.data.cloud_order_number;
          localStorage.setItem(
            "cloud_order_number",
            JSON.stringify(cloud_order_number)
          );
          const orderMessages = JSON.parse(
            localStorage.getItem("storeMessages")
          );
          timeOutError = orderMessages.EFT.Timeout;

          window.Checkout.configure({
            session: {
              id: `${generateSession}`,
            },
            interaction: {
              merchant: {
                name: `${typeForSalesType}`,
                address: {
                  line1: "200 Sample St",
                },
              },
              displayControl: {
                billingAddress: "HIDE",
              },
            },
          });

          window.Checkout.showPaymentPage();
        })
        .catch((error) => {
          message.error(`${timeOutError}`);
          this.setState({ loading: false });
        });
    }, 15000);
  };

  discountOpt = (e) => {
    const { discountFlag, staffDiscount } = this.state;

    const { amountTotal } = this.state;
    const totalAmount = localStorage.getItem("total");
    const amountTotal1 = parseFloat(totalAmount);
    const checkedValue = e.target.checked;

    const comboInfo = JSON.parse(localStorage.getItem("comboIdentifyItems"));

    let staffDiscountPercentage = 0;
    if (staffDiscount.Enabled === true) {
      comboInfo.map((data) => {
        if (data.activity_sn === "2003") {
          staffDiscountPercentage = parseFloat(data.discount_num);
        }
      });
    }

    if (discountFlag !== true && checkedValue === true) {
      const discount = JSON.parse(localStorage.getItem("pagerAndPickupFlags"));

      let gst = discount.GSTPercent;
      let gstAmount = amountTotal / gst;
      let totalAmount = amountTotal / gstAmount;

      totalAmount = totalAmount - (totalAmount * staffDiscountPercentage) / 100;

      totalAmount = totalAmount * gstAmount;
      let discountedAmount = parseFloat(amountTotal1 - totalAmount).toFixed(2);

      const discountMessage = staffDiscount.Message2;

      this.setState({
        amountTotal: totalAmount,
        discountFlag: true,
        discountMessage: discountMessage,
        discountedAmount: discountedAmount,
      });
      localStorage.setItem("discountFlag", JSON.stringify(true));
      localStorage.setItem(
        "discountedAmount",
        parseFloat(discountedAmount).toFixed(2)
      );

      localStorage.setItem("amountTotal", parseFloat(totalAmount).toFixed(2));
    } else if (checkedValue === false) {
      this.setState({
        amountTotal: amountTotal1,
        discountFlag: false,
        discountMessage: "",
      });
      localStorage.setItem("discountFlag", JSON.stringify(false));
      localStorage.setItem("amountTotal", parseFloat(amountTotal1).toFixed(2));
    }
  };

  render() {
    const {
      paymentFlag,
      color,
      highLightFlagForCounter,
      loading,
      amountTotal,
      staffDiscount,

      discountMessage,
    } = this.state;

    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };
    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };
    const responsiveDesignButtons = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 12,
      sm: 12,
      md: 12,
    };

    const responsiveDesignDish = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };
    const intFrameHeight = window.innerHeight;
    const intViewportWidth = window.innerWidth;
    const antIcon = <SyncOutlined spin />;
    return (
      <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{ position: "relative", top: "25em", color: "#F35D5D" }}
        size="large"
      >
        <div
          style={{
            height: `${intFrameHeight}px`,

            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",

            backgroundColor: "#FFFCFB",
          }}
        >
          <Row>
            <Col span={24}>
              <Card
                style={{
                  fontWeight: "regular",
                  fontSize: "25px",
                }}
              >
                <Row
                  style={{
                    margin: "-8px",
                    paddingBottom: "3px",
                  }}
                >
                  <Col
                    {...responsiveDesignForImage}
                    style={{ paddingLeft: "5px" }}
                  >
                    <LeftOutlined
                      onClick={this.onBackToCart}
                      style={{ fontSize: "20px" }}
                    />
                  </Col>
                  <Col
                    {...responsiveDesignForItemData}
                    style={{
                      textAlign: "center",
                      paddingRight: "30px",
                      paddingTop: "7px",
                      fontSize: "20px",
                      color: "#292929",
                    }}
                  >
                    Payment Options
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              paddingTop: "20px",
            }}
          >
            <Col {...responsiveDesignButtons}>
              <p style={{ color: "#343a40", fontSize: "18px" }}>Total Amount</p>
            </Col>
            <Col {...responsiveDesignButtons} style={{ textAlign: "right" }}>
              <p style={{ color: "black", fontSize: "18px" }}>
                ${parseFloat(amountTotal).toFixed(2)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col {...responsiveDesignForColumn} style={{ padding: "14.9px" }}>
              <b
                style={{
                  fontSize: "15px",
                  float: "left",
                  color: "#512C1D",
                  fontWeight: "550",
                }}
              >
                Please select your preferred mode of payment
              </b>
            </Col>
          </Row>
          <Row>
            <Col
              {...responsiveDesignForColumn}
              style={{
                paddingLeft: "7px",
                paddingRight: "7px",
                paddingTop: "7px",
              }}
            >
              <Card
                style={{
                  backgroundColor: "#fff",
                  position: "relative",
                  boxShadow: " 0px 2px 6px -3px",
                }}
                onClick={this.onDisplayPayment}
              >
                <Row style={{ marginBottom: "-8px", paddingTop: "8px" }}>
                  <Col {...responsiveDesignForImage}>
                    <img
                      style={{
                        fontWeight: "700",
                        width: "45px",
                        height: "26px",
                        marginTop: "-3px",
                      }}
                      src={masterCard}
                      alt=""
                    />
                  </Col>
                  <Col {...responsiveDesignDish}>
                    <p
                      style={{
                        fontSize: "15px",
                        paddingLeft: "5px",
                        paddingTop: "2px",
                        color: "#000000",
                      }}
                    >
                      Pay By Credit/Debit Card
                    </p>
                  </Col>
                  <Col
                    {...responsiveDesignForImage}
                    style={{ textAlign: "center" }}
                  >
                    <i
                      className={
                        paymentFlag === false
                          ? "fa fa-check-circle fa-2x"
                          : "fa fa-check-circle-selected fa-2x"
                      }
                      aria-hidden="true"
                    ></i>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col
              {...responsiveDesignForColumn}
              style={{
                paddingLeft: "7px",
                paddingRight: "7px",
                paddingTop: "10px",
              }}
            >
              <Card
                style={{
                  backgroundColor: "#fff",
                  position: "relative",
                  boxShadow: " 0px 2px 6px -3px",
                }}
                onClick={this.onPayAtCounter}
              >
                <Row style={{ marginBottom: "-8px", paddingTop: "8px" }}>
                  <Col {...responsiveDesignForImage}>
                    <img
                      style={{
                        fontWeight: "700",
                        width: "45px",
                        height: "35px",
                        marginTop: "-5px",
                      }}
                      src={payAtCounter}
                      alt=""
                    />
                  </Col>
                  <Col {...responsiveDesignDish}>
                    <p
                      style={{
                        fontSize: "15px",
                        paddingLeft: "5px",
                        paddingTop: "4px",
                        color: `${color}`,
                      }}
                    >
                      Pay At Counter
                    </p>
                  </Col>
                  <Col
                    {...responsiveDesignForImage}
                    style={{ textAlign: "center" }}
                  >
                    <i
                      className={
                        highLightFlagForCounter === false
                          ? "fa fa-check-circle fa-2x"
                          : "fa fa-check-circle-selected fa-2x"
                      }
                      aria-hidden="true"
                    ></i>
                  </Col>

                  <Col
                    {...responsiveDesignForColumn}
                    style={{ marginLeft: "-10px" }}
                  >
                    <img
                      style={{
                        fontWeight: "700",
                        width: "45px",
                        height: "35px",
                        marginTop: "-5px",
                      }}
                      src={Cash}
                      alt=""
                    />
                    <img
                      style={{
                        fontWeight: "700",
                        width: "35px",
                        height: "25px",
                        marginTop: "-5px",
                      }}
                      src={RSVPLogo}
                      alt=""
                    />
                    &ensp;
                    <img
                      style={{
                        fontWeight: "700",
                        width: "30px",
                        height: "25px",
                        marginTop: "-5px",
                      }}
                      src={NETS_Contact}
                      alt=""
                    />
                    &ensp;
                    <img
                      style={{
                        fontWeight: "700",
                        width: "35px",
                        height: "15px",
                        marginTop: "-5px",
                      }}
                      src={NETS_QRmark}
                      alt=""
                    />
                    &ensp;
                    <img
                      style={{
                        fontWeight: "700",
                        width: "35px",
                        height: "20px",
                        marginTop: "-5px",
                      }}
                      src={payNow}
                      alt=""
                    />
                    &ensp;
                    <img
                      style={{
                        fontWeight: "700",
                        width: "35px",
                        height: "20px",
                        marginTop: "-5px",
                      }}
                      src={grabPay}
                      alt=""
                    />
                    &ensp;
                    <img
                      style={{
                        fontWeight: "700",
                        width: "35px",
                        height: "20px",
                        marginTop: "-5px",
                      }}
                      src={Vouchers}
                      alt=""
                    />
                    &ensp;
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <br /> <br />
          {staffDiscount.Enabled === true ? (
            <Row>
              <Col
                {...responsiveDesignForColumn}
                style={{
                  textAlign: "center",
                  marginBottom: "0px",
                }}
              >
                <Button
                  style={{ height: "80%", paddingTop: "8px" }}
                  onClick={this.discountOpt}
                >
                  <Row>
                    <Checkbox
                      style={{ paddingTop: "3px", paddingRight: "5px" }}
                      onChange={this.discountOpt}
                    />

                    <p
                      style={{
                        fontSize: "15px",
                        paddingLeft: "5px",
                        paddingTop: "2px",
                        color: "#000000",
                      }}
                    >
                      {staffDiscount.Message1}
                    </p>
                  </Row>
                </Button>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col
                {...responsiveDesignForColumn}
                style={{
                  paddingLeft: "7px",
                  paddingRight: "7px",
                  paddingTop: "7px",
                  display: "none",
                }}
              ></Col>
            </Row>
          )}
          <Row>
            <Col
              {...responsiveDesignForColumn}
              style={{
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: "15px",
                  paddingLeft: "5px",
                  paddingTop: "2px",
                  color: "#000000",
                }}
              >
                {discountMessage}
              </p>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <br />
          {paymentFlag === true ? (
            <Row
              style={{
                backgroundColor: "#FFFCFB",
                width: `${intViewportWidth}px`,
                paddingTop: "10px",
                position: "absolute",
                bottom: 0,
              }}
            >
              <Col
                {...responsiveDesignForColumn}
                style={{
                  paddingLeft: "7px",
                  paddingRight: "7px",
                  paddingBottom: "7px",
                  paddingTop: "18px",
                }}
              >
                <Card
                  style={{
                    backgroundColor: "#F35D5D",
                    position: "relative",
                  }}
                  onClick={this.onPayFromCard}
                >
                  <Row>
                    <Col
                      {...responsiveDesignForColumn}
                      style={{ textAlign: "center", margin: "0px" }}
                    >
                      <b
                        style={{
                          color: "white",
                          fontWeight: "700",
                          fontSize: "20px",
                        }}
                      >
                        PAY BY CREDIT/DEBIT CARD
                      </b>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row
              style={{
                backgroundColor: "#FFFCFB",
                width: `${intViewportWidth}px`,
                paddingTop: "10px",
                position: "absolute",
                bottom: 0,
                display: "none",
              }}
            ></Row>
          )}
          {highLightFlagForCounter === true ? (
            <Row
              style={{
                backgroundColor: "#FFFCFB",
                width: `${intViewportWidth}px`,
                paddingTop: "10px",
                position: "absolute",
                bottom: 0,
              }}
            >
              <Col
                {...responsiveDesignForColumn}
                style={{
                  paddingLeft: "7px",
                  paddingRight: "7px",
                  paddingBottom: "7px",
                  paddingTop: "18px",
                }}
              >
                <Card
                  style={{
                    backgroundColor: "#F35D5D",
                    position: "relative",
                  }}
                  onClick={this.onFromPayAtCounter}
                >
                  <Row>
                    <Col
                      {...responsiveDesignForColumn}
                      style={{ textAlign: "center", margin: "0px" }}
                    >
                      <b
                        style={{
                          color: "white",
                          fontWeight: "700",
                          fontSize: "20px",
                        }}
                      >
                        PAY AT COUNTER
                      </b>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row
              style={{
                backgroundColor: "#FFFCFB",
                width: `${intViewportWidth}px`,
                paddingTop: "10px",
                position: "absolute",
                bottom: 0,
                display: "none",
              }}
            ></Row>
          )}
        </div>
      </Spin>
    );
  }
}

export default Payment;

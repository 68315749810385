import React from "react";
import { v4 as uuid } from "uuid";
import { Row, Col, Card, Checkbox, Radio } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import "./index.css";

class AddOnCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modifiedItemsTotal: [],
      product: {},
      productPrice: 0.0,
      totaPrice: 0.0,
      modifierItemsDataLength: "",
      productsComboData: [],
      cartArrayId: 0,
      modifiersForChecBox: [],
      lineAddonsData: [],
      comboSno: "",
      modifiersForRadio: [],
      bulletArray: [],
      headerArray: [],
      defaultRadio: 0,
      defalultAddon: undefined,
      defaultRadioddonFlag: false,
    };
  }

  componentDidMount = () => {
    const modifiersArray = JSON.parse(localStorage.getItem("modifiersArray"));
    const comboInfo = JSON.parse(localStorage.getItem("comboIdentifyItems"));
    const itemId = JSON.parse(localStorage.getItem("selectedProductId"));
    const cartItems = JSON.parse(localStorage.getItem("itemsOfCart"));
    const index = cartItems.findIndex((data) => data.Prod_ID === itemId);
    const product = cartItems[index];

    let modifierItemsData = [];
    let modifierItemsDataLength = "";
    let comboSno = "";
    let defaultRadio = 0;
    let defalultAddon = {};
    modifiersArray.map((data) => {
      if (data.min_num === 1) {
        defaultRadio = data.att_val_info[0].att_val_id;
        data.att_val_info.map((value) => {
          if (defaultRadio === value.att_val_id) {
            defalultAddon = value;
            this.setState({
              defaultRadio: defaultRadio,
              defalultAddon: defalultAddon,
            });
          } else {
            this.setState({ defalultAddon: undefined });
          }
        });
      }
    });

    comboInfo.map((data) => {
      if (data.cdata.length !== 1) {
        data.cdata[0].product_id.map((key) => {
          if (key.product_id === product.Prod_ID) {
            modifierItemsData.push(key);
            comboSno = data.activity_sn;
            modifierItemsDataLength = modifierItemsData.length;
          } else {
          }
        });
      }
    });
    this.setState({
      modifiedItemsTotal: modifiersArray,
      product: product,
      productPrice: product.Price,
      modifierItemsDataLength: modifierItemsDataLength,
      productsComboData: comboInfo,
      comboSno: comboSno,
    });
  };

  onBackToItems = () => {
    const { history } = this.props;

    history.push("../../thanks");
  };

  onChange = (e) => {
    const {
      product,
      modifiedItemsTotal,
      modifiersForChecBox,
      lineAddonsData,
    } = this.state;
    const cartArrayId = e.target.value.att_val_sn;
    const addonObject = e.target.value;
    const addOnCheckedValue = e.target.checked;

    let listPrice = "";
    let headerId = "";

    if (addOnCheckedValue === true) {
      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.att_val_info.findIndex(
          (item) => item.att_val_id === addonObject.att_val_id
        );
        if (itemIndex > -1)
          modifiedItemsTotal[mi].att_val_info[itemIndex].checked = true;
      });

      modifiedItemsTotal.map((data) => {
        data.att_val_info.map((value) => {
          if (addonObject.att_val_id === value.att_val_id) {
            lineAddonsData.push(addonObject);
            // headerId = data.att_id;

            const desc = JSON.parse(addonObject.att_val_name).cn;
            modifiersForChecBox.push({
              description: `${desc}`,
              prodnum: addonObject.att_val_sn,
              quantity: 1,
            });

            const index = lineAddonsData.findIndex(
              (data) => data.att_val_sn === cartArrayId
            );
            product.Price = (
              parseFloat(product.Price) +
              parseFloat(lineAddonsData[index].price)
            ).toFixed(2);
            listPrice = product.Price;
          }
        });
      });

      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.att_val_info.findIndex((item) =>
          item.hasOwnProperty("checked")
        );
        if (itemIndex > -1) {
          modifiedItemsTotal[mi].selected = true;
        } else {
          modifiedItemsTotal[mi].selected = false;
        }
      });

      this.setState({
        product: product,
        modifiersForChecBox: modifiersForChecBox,
        totaPrice: listPrice,
        lineAddonsData: lineAddonsData,
        headerArray: modifiedItemsTotal,
      });
      localStorage.setItem(
        "modifiedItemsTotal",
        JSON.stringify(modifiedItemsTotal)
      );
    } else {
      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.att_val_info.findIndex(
          (item) => item.att_val_id === addonObject.att_val_id
        );
        if (itemIndex > -1)
          delete modifiedItemsTotal[mi].att_val_info[itemIndex].checked;
      });

      const index = lineAddonsData.findIndex(
        (data) => data.att_val_sn === cartArrayId
      );
      const index1 = modifiersForChecBox.findIndex(
        (data) => data.att_val_sn === cartArrayId
      );
      if (index >= 0 || index1 >= 0) {
        product.Price = (
          parseFloat(product.Price) - parseFloat(lineAddonsData[index].price)
        ).toFixed(2);
        modifiersForChecBox.splice(index, 1);
        lineAddonsData.splice(index, 1);
        listPrice = product.Price;
      }
      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.att_val_info.findIndex((item) =>
          item.hasOwnProperty("checked")
        );
        if (itemIndex > -1) modifiedItemsTotal[mi].selected = true;
      });

      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.att_val_info.findIndex((item) =>
          item.hasOwnProperty("checked")
        );
        if (itemIndex > -1) {
          modifiedItemsTotal[mi].selected = true;
        } else {
          modifiedItemsTotal[mi].selected = false;
        }
      });

      this.setState({
        product: product,
        modifiersForChecBox: modifiersForChecBox,
        totaPrice: listPrice,
        lineAddonsData: lineAddonsData,
        headerArray: modifiedItemsTotal,
      });
      localStorage.setItem(
        "modifiedItemsTotal",
        JSON.stringify(modifiedItemsTotal)
      );
    }
  };

  onRadio = (e) => {
    const { product, modifiedItemsTotal } = this.state;
    const defaultRadioddonFlag = true;
    const addOnCheckedValue = e.target.checked;
    const cartArrayId = e.target.value;
    let addOns = [];
    let listPrice = "";
    let modifiersForRadio = [];
    const lineAddonsData = [];
    if (addOnCheckedValue === true) {
      modifiedItemsTotal.map((data) => {
        addOns = data.att_val_info;
        addOns.map((value) => {
          if (cartArrayId === value.att_val_id) {
            lineAddonsData.push(value);
            const desc = JSON.parse(value.att_val_name).cn;
            modifiersForRadio.push({
              description: `${desc}`,
              prodnum: value.att_val_sn,
              quantity: 1,
            });

            if (lineAddonsData.length >= 1) {
              product.Price = (
                parseFloat(product.Price) + parseFloat(value.price)
              ).toFixed(2);

              listPrice = product.Price;
            }
          }
          return true;
        });
        return true;
      });
    }

    this.setState({
      cartArrayId: cartArrayId,
      modifiersForRadio: modifiersForRadio,
      totaPrice: listPrice,
      product: product,
      lineAddonsData: lineAddonsData,
      defaultRadio: 0,
      defaultRadioddonFlag: defaultRadioddonFlag,
    });
  };

  onAddOnCart = () => {
    const { history } = this.props;

    const {
      product,
      modifiersForChecBox,
      modifiersForRadio,
      totaPrice,
      lineAddonsData,
      headerArray,
    } = this.state;
    const basket = JSON.parse(localStorage.getItem("basket"));
    const combinationId = uuid().replace(/-/g, "").toUpperCase();

    let Modifiers = [];

    modifiersForRadio.map((data) => {
      Modifiers.push(data);
    });
    modifiersForChecBox.map((data) => {
      Modifiers.push(data);
    });

    product.Modifiers = Modifiers;
    product.Quantity = 1;
    product.listPrice = totaPrice;
    product.selectedAddons = lineAddonsData;
    product.headerArray = headerArray;
    product.combinationId = combinationId;

    basket.push(product);

    let total = 0.0;

    for (let i = 0; i < basket.length; i += 1) {
      if (basket[i].listPrice) {
        total +=
          parseFloat(basket[i].listPrice).toFixed(2) * basket[i].Quantity;
      } else {
        total += parseFloat(basket[i].Price).toFixed(2) * basket[i].Quantity;
      }
    }
    localStorage.setItem("basket", JSON.stringify(basket));
    localStorage.setItem("total", parseFloat(total).toFixed(2));
    localStorage.setItem("modifiedItemsTotal", JSON.stringify([]));

    history.push("../../thanks");
  };

  onClickOfContinue = () => {
    const { history } = this.props;
    const {
      product,
      comboSno,
      modifiersForChecBox,
      totaPrice,
      modifiersForRadio,
      lineAddonsData,
      defalultAddon,
      headerArray,
    } = this.state;
    let Modifiers = [];

    if (modifiersForRadio.length === 0 && defalultAddon !== undefined) {
      lineAddonsData.push(defalultAddon);
      const desc = JSON.parse(defalultAddon.att_val_name).cn;
      modifiersForRadio.push({
        description: `${desc}`,
        prodnum: defalultAddon.att_val_sn,
        quantity: 1,
      });
    }

    modifiersForRadio.map((data) => {
      Modifiers.push(data);
    });
    modifiersForChecBox.map((data) => {
      Modifiers.push(data);
    });
    product.Modifiers = Modifiers;
    product.Quantity = 1;
    product.listPrice = totaPrice;
    product.comboSno = comboSno;
    product.selectedAddons = lineAddonsData;
    product.headerArray = headerArray;
    localStorage.setItem("productForBasket", JSON.stringify(product));
    history.push("../comboItems/index.js");
  };

  render() {
    const {
      modifiedItemsTotal,
      product,
      productPrice,
      totaPrice,
      modifierItemsDataLength,
      productsComboData,
      cartArrayId,
      defaultRadio,
      headerArray,
    } = this.state;
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };

    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };

    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };

    const intViewportWidth = window.innerWidth;
    const intFrameHeight = window.innerHeight;

    return (
      <div
        style={{
          margin: "0px",
          height: `${intFrameHeight}px`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",

          backgroundColor: "#fff",
        }}
      >
        <Row>
          <Col {...responsiveDesignForColumn}>
            <LeftOutlined
              onClick={this.onBackToItems}
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                color: "black",
                marginTop: "15px",
                paddingLeft: "10px",
                position: "absolute",
              }}
              size="large"
              fontWeight="bold"
            />

            <img
              src={product.Image_Url}
              alt="product"
              width="100%"
              height="100%"
              style={{ borderRadius: "0px" }}
            />
          </Col>
        </Row>

        <Row>
          <Col {...responsiveDesignForColumn}>
            <Card style={{ backgroundColor: "#EFEFEF", borderRadius: "0px" }}>
              <Row style={{ margin: "0px" }}>
                <Col {...responsiveDesignForItemData}>
                  <b style={{ fontSize: "3.8vw" }}>{product.Name} </b>
                </Col>
                <Col {...responsiveDesignForImage}>
                  <p
                    style={{
                      color: "black",
                      fontSize: "20px",
                      paddingLeft: "4px",
                    }}
                  >
                    ${totaPrice === 0.0 ? productPrice : totaPrice}
                  </p>
                </Col>
              </Row>

              <Row>
                <div style={{ maxHeight: "55px", overflowY: "scroll" }}>
                  {headerArray.map((values) => {
                    if (values.selected) {
                      return (
                        <div>
                          {values.selected === true ? (
                            <div>
                              <Col {...responsiveDesignForColumn}>
                                <b style={{ fontSize: "3.3vw" }}>
                                  {" "}
                                  {JSON.parse(values.att_name).en}{" "}
                                </b>
                              </Col>
                              {values.att_val_info.map((data) => {
                                if (data.checked) {
                                  return (
                                    <div>
                                      {data.checked === true ? (
                                        <ul>
                                          <li>
                                            {" "}
                                            {JSON.parse(data.att_val_name).cn}
                                            &nbsp;{" "}
                                          </li>
                                        </ul>
                                      ) : (
                                        <ul style={{ display: "none" }}>
                                          <li>
                                            {" "}
                                            {JSON.parse(data.att_val_name).cn}
                                            &nbsp;{" "}
                                          </li>
                                        </ul>
                                      )}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <ul style={{ display: "none" }}>
                                      <li> </li>
                                    </ul>
                                  );
                                }
                              })}
                            </div>
                          ) : (
                            <Col
                              {...responsiveDesignForColumn}
                              style={{ display: "none" }}
                            >
                              <b style={{ fontSize: "3.3vw" }}></b>
                            </Col>
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          <Col
                            {...responsiveDesignForColumn}
                            style={{ display: "none" }}
                          >
                            <b style={{ fontSize: "3.3vw" }}>
                              {JSON.parse(values.att_name).en}{" "}
                            </b>
                          </Col>
                        </div>
                      );
                    }
                  })}
                </div>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row
          {...responsiveDesignForColumn}
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingBottom: "10px",
            marginLeft: "0px",
            backgroundColor: "#fff",
          }}
        >
          <Col {...responsiveDesignForColumn}>
            <div
              style={{
                maxHeight: "380px",
                overflowY: "scroll",
                paddingBottom: "25px",
              }}
            >
              {modifiedItemsTotal.map((values) => {
                return (
                  <Form style={{ paddingBottom: "0px" }}>
                    <FormItem
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "0px",
                        padding: "8px",
                      }}
                    >
                      {" "}
                      <Col {...responsiveDesignForItemData}>
                        <b style={{ fontSize: "4.3vw" }}>
                          {JSON.parse(values.att_name).en}{" "}
                        </b>
                      </Col>
                    </FormItem>

                    <FormItem>
                      <Col>
                        {values.att_val_info.map((data) => {
                          return (
                            <Col
                              {...responsiveDesignForColumn}
                              style={{ paddingBottom: "10px" }}
                            >
                              {values.max_num === 1 ? (
                                <Radio.Group
                                  name="radiogroup"
                                  className={
                                    cartArrayId === data.att_val_id
                                      ? "ant-radio ant-radio-checked"
                                      : "ant-radio "
                                  }
                                  onChange={this.onRadio}
                                  value={defaultRadio}
                                >
                                  <Radio value={data.att_val_id}>
                                    {" "}
                                    {JSON.parse(data.att_val_name).cn} (+$
                                    {data.price})
                                  </Radio>
                                </Radio.Group>
                              ) : (
                                <Checkbox
                                  value={data}
                                  style={{ fontSize: "16px" }}
                                  onChange={this.onChange}
                                >
                                  {JSON.parse(data.att_val_name).cn} (+$
                                  {data.price})
                                </Checkbox>
                              )}
                            </Col>
                          );
                        })}
                      </Col>
                    </FormItem>
                  </Form>
                );
              })}
            </div>

            {productsComboData.map((data) => {
              return (
                <div>
                  {data.cdata.map((value) => {
                    return (
                      <div>
                        {value.product_id.map((key) => {
                          return (
                            <div>
                              {key.product_id === product.Prod_ID ||
                              modifierItemsDataLength === 2 ? (
                                <Row
                                  style={{
                                    position: "fixed",
                                    bottom: 0,
                                    width: `${intViewportWidth}px`,
                                    paddingBottom: "9px",
                                    paddingRight: "15px",
                                    textAlign: "center",
                                  }}
                                >
                                  <Col
                                    {...responsiveDesignForColumn}
                                    style={{
                                      paddingTop: "0px",
                                      paddingLeft: "0px",
                                      paddingBottom: "0px",
                                      paddingRight: "4px",
                                    }}
                                  >
                                    <Card
                                      style={{
                                        backgroundColor: "#F35D5D",
                                        position: "relative",
                                        height: "3.0rem",
                                      }}
                                      onClick={() => this.onClickOfContinue()}
                                    >
                                      <p
                                        style={{
                                          color: "white",
                                          fontWeight: "700",
                                          fontSize: "20px",
                                          marginTop: "-2px",
                                          paddingLeft: "7px",
                                        }}
                                      >
                                        CONTINUE
                                      </p>
                                    </Card>
                                  </Col>
                                </Row>
                              ) : (
                                <div>
                                  {modifierItemsDataLength === 1 ? (
                                    <Row
                                      style={{
                                        position: "fixed",
                                        bottom: 0,
                                        width: `${intViewportWidth}px`,
                                        paddingBottom: "9px",
                                        paddingRight: "15px",
                                        display: "none",
                                      }}
                                    ></Row>
                                  ) : (
                                    <Row
                                      style={{
                                        position: "fixed",
                                        bottom: 0,
                                        width: `${intViewportWidth}px`,
                                        paddingBottom: "9px",
                                        paddingRight: "15px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Col {...responsiveDesignForColumn}>
                                        <Card
                                          style={{
                                            backgroundColor: "#F35D5D",
                                          }}
                                          onClick={() => this.onAddOnCart()}
                                        >
                                          <Row>
                                            <Col {...responsiveDesignForColumn}>
                                              <b
                                                style={{
                                                  color: "white",
                                                  fontWeight: "700",
                                                  fontSize: "20px",
                                                }}
                                              >
                                                ADD TO CART
                                              </b>
                                            </Col>
                                          </Row>
                                        </Card>
                                      </Col>
                                    </Row>
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Col>
        </Row>

        <br />
      </div>
    );
  }
}

export default AddOnCart;

import React from "react";
import { v4 as uuid } from "uuid";
import { Row, Col, Spin, Card } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import axios from "axios";

import "./thanks.css";

class Thanks extends React.Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();

    this.state = {
      subCategoryItems: [],
      allProductsAtt: [],
      comparingProducts: [],
      storeId: "",
      Category_Id: 0,
      subCategory_Id: "",
      itemsValue: 0.0,
      product: {},
      basket: [],
      categoryItems: [],
      total: 0.0,
      allProducts: [],
      loading: false,
      Category_IdFor: 0,
      buttonFlag: false,
      scrollPosition: 300,
      apiDetails: {},
      decreamentFlag: false,
      customProduct: "",
      customBasket: [],
    };
  }

  componentWillMount = () => {
    const { allProducts } = this.state;
    const Category_IdFor = localStorage.getItem("Category_Id");

    this.setState({
      allProducts: allProducts,
      Category_IdFor: Category_IdFor,
    });
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    const storeId = localStorage.getItem("storeId");
    const basket = JSON.parse(localStorage.getItem("basket"));
    const storeNote = JSON.parse(localStorage.getItem("pagerAndPickupFlags"));
    const buttonFlag = storeNote.AddButtonAtSide;
    this.setState({ buttonFlag: buttonFlag });
    const total = parseFloat(localStorage.getItem("total"));
    const apiDetails = JSON.parse(localStorage.getItem("apiDetails"));
    const md5 = require("md5");
    const uri1 = "api/mobile/getProductAtt";
    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);
    const Request_Body = JSON.stringify({ store_id: `${storeId}` });
    const sign = md5(
      apiDetails.appKey +
        apiDetails.appSecret +
        uri1 +
        Request_Body +
        secondsSinceEpoch
    ).toString();

    const config1 = {
      method: "post",
      url: `${apiDetails.apiDomain}/`.concat(`${uri1}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${apiDetails.tenantID}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${apiDetails.appKey}`,
        "Serial-Number": `${apiDetails.deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: Request_Body,
    };
    axios(config1)
      .then((response) => {
        const allProducts = response.data.atts;

        this.setState({ allProductsAtt: allProducts, apiDetails: apiDetails });

        this.detailsOfItem();
        this.detailsOfMenu();
        localStorage.setItem("comboDrinksAtts", JSON.stringify(allProducts));
      })
      .catch((error) => {
        console.log(error);
      });

    if (basket !== null && basket.length > 0) {
      this.setState({
        basket,
        total,
      });
    }
  };

  scrollerFunction = () => {
    if (this.scrollRef.current !== null) {
      this.scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  detailsOfMenu = () => {
    const { apiDetails } = this.state;
    const md5 = require("md5");
    const uri = "api/mobile/getMenu";

    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);
    const storeId = localStorage.getItem("storeId");
    const Request_Body = JSON.stringify({ store_id: `${storeId}` });
    const sign = md5(
      apiDetails.appKey +
        apiDetails.appSecret +
        uri +
        Request_Body +
        secondsSinceEpoch
    ).toString();

    const allCategories = [];
    const config = {
      method: "post",
      url: `${apiDetails.apiDomain}/`.concat(`${uri}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${apiDetails.tenantID}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${apiDetails.appKey}`,
        "Serial-Number": `${apiDetails.deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: Request_Body,
    };

    axios(config)
      .then((response) => {
        const menus = response.data.menu;
        menus.map((data) => {
          const menusDetails = JSON.parse(data.cat_name);
          menusDetails.Catrgory_ID = data.id;
          menusDetails.Cat_pic = data.cat_pic;
          menusDetails.category_SNo = data.category_sn;
          menusDetails.subCategory = data.child;
          menusDetails.sort_sn = data.sort_sn;

          allCategories.push(menusDetails);
          allCategories.sort(function (a, b) {
            return a.sort_sn > b.sort_sn ? 1 : b.sort_sn > a.sort_sn ? -1 : 0;
          });
          let Category_IdFor = 0;
          Category_IdFor = JSON.parse(localStorage.getItem("Category_Id"));
          if (Category_IdFor === null) {
            localStorage.setItem("Category_Id", allCategories[0].Catrgory_ID);
          } else {
            this.setState({ Category_IdFor: Category_IdFor });
          }

          return true;
        });

        this.setState({ categoryItems: allCategories });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  detailsOfItem = () => {
    const { apiDetails } = this.state;
    this.setState({ loading: true });

    const md5 = require("md5");
    const uri = "api/mobile/getProductByStore";

    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);
    const storeId = localStorage.getItem("storeId");
    const Request_Body = JSON.stringify({ store_id: `${storeId}` });
    const sign = md5(
      apiDetails.appKey +
        apiDetails.appSecret +
        uri +
        Request_Body +
        secondsSinceEpoch
    ).toString();
    let products = {};
    const totalProducts = [];
    const activeCatProducts = [];
    const config = {
      method: "post",
      url: `${apiDetails.apiDomain}/`.concat(`${uri}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${apiDetails.tenantID}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${apiDetails.appKey}`,
        "Serial-Number": `${apiDetails.deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: Request_Body,
    };
    axios(config)
      .then((response) => {
        const allProducts = response.data.products;
        let Category_IdFor = JSON.parse(localStorage.getItem("Category_Id"));
        allProducts.map((data) => {
          products = JSON.parse(data.product_name);
          products.Name = JSON.parse(data.product_name).cn;
          products.category_id = data.cate_id;
          products.Price = data.price;
          products.Prod_ID = data.product_id;
          products.Prod_IDFor = data.product_sn;
          products.Prod_ID = data.product_id;
          products.Customizable = data.hasModifiers;
          products.Image_Url = data.product_pic1;
          products.sort_sn = data.sort_sn;
          products.note = data.note;

          if (Category_IdFor === data.cate_id) {
            activeCatProducts.push(products);
            this.scrollerFunction();
          }
          totalProducts.push(products);
          totalProducts.sort(function (a, b) {
            return a.sort_sn > b.sort_sn ? 1 : b.sort_sn > a.sort_sn ? -1 : 0;
          });
          activeCatProducts.sort(function (a, b) {
            return a.sort_sn > b.sort_sn ? 1 : b.sort_sn > a.sort_sn ? -1 : 0;
          });

          return true;
        });
        localStorage.setItem("itemsOfCart", JSON.stringify(totalProducts));
        this.setState({
          allProducts: activeCatProducts,
          comparingProducts: totalProducts,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onViewCartClick = () => {
    const { history } = this.props;
    history.push("./viewCart/insideCart/index.js");
  };

  onAddOns = () => {
    localStorage.setItem("showButtonFlag", JSON.stringify(true));
    const { history } = this.props;
    history.push("./viewCart/addOnCart/index.js");
  };

  onAddOnCart = () => {
    const { history } = this.props;
    history.push("./viewCart/insideCart/index.js");
    this.setState({ flagFor: true });
  };

  onAddPlus = () => {
    const { history } = this.props;
    history.push("./viewCart/additionalCart/index.js");
    this.setState({ flagFor: true });
  };

  addProductToBasket = (product, QuantityFor) => {
    const { basket } = this.state;
    const combinationId = uuid().replace(/-/g, "").toUpperCase();
    let customBasket = [];
    if (product.Customizable === 1) {
      basket.map((data) => {
        if (data.Prod_ID === product.Prod_ID) {
          customBasket.push(data);
        }
      });
      customBasket.map((data) => {
        if (!data.listPrice || data.listPrice === 0) {
          data.customizeTotal =
            parseFloat(data.Price).toFixed(2) * data.Quantity;
        } else {
          data.customizeTotal =
            parseFloat(data.listPrice).toFixed(2) * data.Quantity;
        }
      });

      this.setState({
        decreamentFlag: true,
        customProduct: product.Name,
        customBasket: customBasket,
      });
    } else {
      const index = basket.findIndex(
        (basketItem) =>
          (basketItem.combinationId === product.combinationId ||
            basketItem.Prod_ID === product.Prod_ID) &&
          parseFloat(basketItem.Quantity) >= 0
      );

      if (index >= 0) {
        basket[index].Quantity = basket[index].Quantity + QuantityFor;
        if (basket[index].Quantity <= 0) {
          basket.splice(index, 1);
        }
      } else {
        product.Quantity = QuantityFor;
        product.combinationId = combinationId;
        basket.push(product);
      }

      let total = 0.0;
      let itemsQuantity = 0.0;
      for (let i = 0; i < basket.length; i += 1) {
        if (
          basket[i].listPrice === 0 ||
          basket[i].listPrice === null ||
          basket[i].listPrice === undefined
        ) {
          total += parseFloat(basket[i].Price) * parseFloat(basket[i].Quantity);
        } else {
          total +=
            parseFloat(basket[i].listPrice) * parseFloat(basket[i].Quantity);
        }
        itemsQuantity += basket[i].Quantity;
      }
      this.setState({ basket: basket, total: total });

      localStorage.setItem("basket", JSON.stringify(basket));
      localStorage.setItem("total", total);
      localStorage.setItem("itemsQuantity", itemsQuantity);
    }
  };

  selectProduct = (productId, data, type) => {
    const { history } = this.props;
    const { allProductsAtt } = this.state;

    localStorage.setItem("selectedProductId", productId);
    let modifiers = [];

    allProductsAtt.map((data) => {
      if (productId === data.product_id) {
        modifiers = data.atts;
      }
    });

    localStorage.setItem("modifiersArray", JSON.stringify(modifiers));

    if (type === "new") {
      history.push("./viewCart/addOnCart/index.js");
    } else {
      history.push("./viewCart/additionalCart/index.js");
    }
  };

  onClickOfsubCat = (key) => {
    const { comparingProducts } = this.state;
    let allProductsData = [];
    comparingProducts.map((data) => {
      if (key === data.category_id) {
        allProductsData.push(data);
        allProductsData.sort(function (a, b) {
          return a.sort_sn > b.sort_sn ? 1 : b.sort_sn > a.sort_sn ? -1 : 0;
        });
      }
      return true;
    });

    this.setState({
      subCategory_Id: key,
      allProducts: allProductsData,
    });
  };

  onClickOfCategory = (key) => {
    const { categoryItems, comparingProducts } = this.state;
    localStorage.setItem("Category_Id", key);

    let allProductsData = [];
    let allProductsDataSub = [];

    categoryItems.map((data) => {
      if (key === data.Catrgory_ID) {
        allProductsDataSub = data.subCategory;
      }

      return true;
    });

    comparingProducts.map((data) => {
      if (key === data.category_id) {
        allProductsData.push(data);
        allProductsData.sort(function (a, b) {
          return a.sort_sn > b.sort_sn ? 1 : b.sort_sn > a.sort_sn ? -1 : 0;
        });
      }
      return true;
    });

    this.setState({
      subCategoryItems: allProductsDataSub,
      Category_Id: key,
      Category_IdFor: 0,
      allProducts: allProductsData,
    });
  };

  handleCancel = () => {
    this.setState({ decreamentFlag: false });
  };

  customizeToBasket = (product, Quantity) => {
    const { basket } = this.state;
    let customBasket = [];

    const index = basket.findIndex(
      (basketItem) =>
        basketItem.combinationId === product.combinationId &&
        parseFloat(basketItem.Quantity) >= 0
    );

    if (index >= 0) {
      basket[index].Quantity = parseFloat(basket[index].Quantity) + Quantity;
      if (basket[index].Quantity <= 0) {
        product.customizeTotal =
          parseFloat(basket[index].listPrice).toFixed(2) *
          basket[index].Quantity;
        basket.splice(index, 1);
      }
    } else {
      basket.push(product);
    }

    basket.map((data) => {
      if (data.Prod_ID === product.Prod_ID) {
        customBasket.push(data);
      }
    });

    customBasket.map((data) => {
      if (!data.listPrice || data.listPrice === 0) {
        data.customizeTotal = parseFloat(data.Price).toFixed(2) * data.Quantity;
      } else {
        data.customizeTotal =
          parseFloat(data.listPrice).toFixed(2) * data.Quantity;
      }
    });
    let total = 0.0;
    let itemsValues = 0.0;
    for (let i = 0; i < basket.length; i += 1) {
      if (basket[i].listPrice) {
        total +=
          parseFloat(basket[i].listPrice).toFixed(2) * basket[i].Quantity;
      } else {
        total += parseFloat(basket[i].Price).toFixed(2) * basket[i].Quantity;
      }

      itemsValues += basket[i].Quantity;
    }

    this.setState({
      basket: basket,
      total: total,
      itemsValue: itemsValues,
      customBasket: customBasket,
    });

    localStorage.setItem("basket", JSON.stringify(basket));
    localStorage.setItem("total", parseFloat(total).toFixed(2));
    localStorage.setItem("itemsQuantity", itemsValues);
  };

  render() {
    const {
      subCategory_Id,
      Category_Id,
      subCategoryItems,
      allProducts,
      basket,
      loading,
      total,
      categoryItems,
      Category_IdFor,
      buttonFlag,
      decreamentFlag,
      customProduct,
      customBasket,
    } = this.state;
    const disableViewCart = basket.length;

    let itemsQuantity = 0.0;
    for (let i = 0; i < basket.length; i += 1) {
      itemsQuantity += basket[i].Quantity;
    }
    const intFrameHeight = window.outerHeight / 1.865;

    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };

    const responsiveDesignForItemData = {
      xxl: 18,
      xl: 18,
      lg: 18,
      xs: 18,
      sm: 18,
      md: 18,
    };

    const responsiveDesignCustom = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };

    const responsiveDesignButtons = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 12,
      sm: 12,
      md: 12,
    };

    const responsiveDesignText = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };

    const responsiveDesignForList = {
      xxl: 6,
      xl: 6,
      lg: 6,
      xs: 6,
      sm: 6,
      md: 6,
    };

    const intViewportWidth = window.innerWidth;
    const outerWidth = window.outerWidth;
    const intFrameHeightFor = window.innerHeight / 1.55;
    const customHeight = window.innerHeight;
    const antIcon = <SyncOutlined spin />;

    return (
      <div>
        <Spin
          spinning={loading}
          tip="Loading..."
          indicator={antIcon}
          style={{ position: "relative", top: "20em", color: "#f35d5d" }}
          size="large"
        >
          <section
            style={{
              margin: "8px",
              position: "relative",
              height: "100%",
            }}
            className="po"
          >
            <div className="scrolling-wrapper">
              {categoryItems.map((Select) => {
                return (
                  <div className="row2">
                    <div
                      className="col-lg-12 col-m-12 col-s-6 col-sm-6 col-xs-6"
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    >
                      <div
                        className={
                          Category_Id === Select.Catrgory_ID ||
                          Category_IdFor === Select.Catrgory_ID
                            ? "subCategory-selected"
                            : "subCategory"
                        }
                        value={Select.Catrgory_ID}
                        ref={
                          Category_Id === Select.Catrgory_ID ||
                          Category_IdFor === Select.Catrgory_ID
                            ? this.scrollRef
                            : null
                        }
                        onClick={() =>
                          this.onClickOfCategory(Select.Catrgory_ID)
                        }
                      >
                        <div className="row1">
                          <img
                            src={Select.Cat_pic}
                            alt="Category_pic"
                            width="55"
                            height="50"
                            style={{ borderRadius: "50%" }}
                          />
                        </div>
                        <div className="row" style={{ paddingTop: "0px" }}>
                          <b
                            style={{
                              whiteSpace: "normal",
                              fontSize: "11.5px",
                            }}
                          >
                            {Select.cn}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {categoryItems.map((Select) => {
              if (
                Category_Id === Select.Catrgory_ID &&
                Select.subCategory.length >= 1
              ) {
                return (
                  <div className="scrolling-wrapper">
                    {subCategoryItems.map((sub) => {
                      return (
                        <div className="row2">
                          <div
                            className="col-lg-12 col-m-12 col-s-6 col-sm-6 col-xs-6"
                            style={{ paddingLeft: "3px", paddingRight: "3px" }}
                          >
                            <div
                              className={
                                subCategory_Id === sub.id
                                  ? "subChildCategory-selected"
                                  : "subChildCategory"
                              }
                              onClick={() => this.onClickOfsubCat(sub.id)}
                            >
                              <div
                                className={
                                  subCategory_Id === sub.id
                                    ? "rowImage"
                                    : "row1"
                                }
                              >
                                <img
                                  src={sub.cat_pic}
                                  alt="cat_pic"
                                  width="36"
                                  height="36"
                                  style={{ borderRadius: "50%" }}
                                />
                              </div>
                              <div
                                className={
                                  subCategory_Id === sub.id
                                    ? "rowSubCategory"
                                    : "rowSub"
                                }
                                style={{ paddingTop: "0px" }}
                              >
                                <b
                                  style={{
                                    whiteSpace: "normal",
                                    fontSize: "10.5px",
                                  }}
                                >
                                  {JSON.parse(sub.cat_name).cn}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              } else {
                return (
                  <Row style={{ display: "none" }}>
                    <Col {...responsiveDesignForColumn}>
                      <h6 className="mt-1 text-shadow font-weight-normal">
                        <Card>
                          <b>subCategory</b>
                        </Card>
                      </h6>
                    </Col>
                  </Row>
                );
              }
            })}

            <div
              className="productList"
              style={{
                maxHeight: `${intFrameHeight}px`,
                paddingBottom: "25px",
              }}
            >
              {allProducts.map((data) => {
                return (
                  <div>
                    <Row>
                      <Col {...responsiveDesignForColumn}>
                        <Card
                          style={{
                            borderRadius: "0px",
                          }}
                        >
                          <Row>
                            <Col {...responsiveDesignForItemData}>
                              <Row>
                                <Col
                                  lg={24}
                                  style={{ paddingRight: "6%" }}
                                  onClick={
                                    data.Customizable === 0
                                      ? () => {
                                          this.addProductToBasket(data, 1);
                                        }
                                      : () => {
                                          this.selectProduct(
                                            data.Prod_ID,
                                            data,
                                            "new"
                                          );
                                        }
                                  }
                                >
                                  <b>{data.Name} </b>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={24}>
                                  <b>${data.Price} </b>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={24}>
                                  <b>{data.note} </b>
                                </Col>
                              </Row>
                            </Col>
                            <Col {...responsiveDesignForList}>
                              <img
                                src={data.Image_Url}
                                alt="product"
                                width="95"
                                height="70"
                                style={{ float: "right", borderRadius: "5px" }}
                                onClick={
                                  data.Customizable === 0
                                    ? () => {
                                        this.addProductToBasket(data, 1);
                                      }
                                    : () => {
                                        this.selectProduct(
                                          data.Prod_ID,
                                          data,
                                          "new"
                                        );
                                      }
                                }
                              />

                              {basket.findIndex(
                                (basketItem) =>
                                  basketItem.Prod_ID === data.Prod_ID &&
                                  parseFloat(basketItem.Quantity) >= 0
                              ) >= 0 ? (
                                <div
                                  className={
                                    buttonFlag === true
                                      ? "divisionstyleTrue"
                                      : "divisionstyle"
                                  }
                                >
                                  <button
                                    className="minusbtn"
                                    width="20px"
                                    onClick={() =>
                                      this.addProductToBasket(data, -1)
                                    }
                                  >
                                    -
                                  </button>

                                  <button className="addingbtn" width="20px">
                                    {(() => {
                                      let qty = 0;
                                      basket.map((current) => {
                                        if (
                                          current.Prod_ID === data.Prod_ID &&
                                          parseFloat(current.Quantity) >= 0
                                        ) {
                                          qty += current.Quantity;
                                        }
                                        return null;
                                      });
                                      return qty;
                                    })()}
                                  </button>

                                  <button
                                    key={data.Prod_ID}
                                    className="addbtnplus"
                                    width="20px"
                                    onClick={
                                      data.Customizable === 0
                                        ? () => {
                                            this.addProductToBasket(data, 1);
                                          }
                                        : () => {
                                            this.selectProduct(
                                              data.Prod_ID,
                                              data,
                                              "exisitng"
                                            );
                                          }
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              ) : (
                                <button
                                  className={
                                    buttonFlag === true
                                      ? "addbtnTrue"
                                      : "addbtn"
                                  }
                                  key={data.Prod_ID}
                                  onClick={
                                    data.Customizable === 0
                                      ? () => {
                                          this.addProductToBasket(data, 1);
                                        }
                                      : () => {
                                          this.selectProduct(
                                            data.Prod_ID,
                                            data,
                                            "new"
                                          );
                                        }
                                  }
                                >
                                  {data.Customizable === 0 ? (
                                    <h5 className="addingBtnStyles"> Add </h5>
                                  ) : (
                                    <h5 className="addingCustBtnStyles">
                                      {" "}
                                      Add+
                                    </h5>
                                  )}
                                </button>
                              )}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
            <br />
            {disableViewCart === 0 ? (
              <Row
                style={{ position: "absolute", bottom: 0, display: "none" }}
              ></Row>
            ) : (
              <Row
                style={{
                  position: "fixed",
                  bottom: 0,
                  width: `${intViewportWidth}px`,
                  paddingBottom: "7px",
                  paddingRight: "16px",
                }}
              >
                <Col {...responsiveDesignForColumn}>
                  <Card
                    style={{
                      backgroundColor: "#F35D5D",
                    }}
                    onClick={() => {
                      this.onViewCartClick();
                    }}
                  >
                    <Row>
                      <Col {...responsiveDesignForItemData}>
                        <b style={{ color: "white" }}>
                          {itemsQuantity} items | ${" "}
                          {parseFloat(total).toFixed(2)}
                        </b>
                      </Col>
                      <Col {...responsiveDesignForList}>
                        <b style={{ color: "white", float: "right" }}>
                          View Cart
                        </b>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
          </section>
        </Spin>
        {decreamentFlag === true && customBasket.length !== 0 ? (
          <div
            style={{
              backgroundColor: "rgba(28,28,28,0.68)",
              borderRadius: "10px",
              top: 0,
              position: "absolute",
              width: `${outerWidth}px`,
              height: `${customHeight}px`,
              opacity: "99%",
              zIndex: "500",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                bottom: 0,
                position: "absolute",
                width: `${outerWidth}px`,
                zIndex: "1000",
              }}
            >
              {" "}
              <Row style={{ textAlign: "center" }}>
                <Col
                  {...responsiveDesignForColumn}
                  style={{ padding: "10px", textAlign: "left" }}
                >
                  <Row>
                    <Col {...responsiveDesignForItemData}>
                      <p
                        style={{
                          color: "rgb(81, 44, 29)",

                          fontSize: "20px",
                          fontWeight: "700",
                          marginBottom: "-0.1rem",
                        }}
                      >
                        Customizations for
                      </p>
                    </Col>
                    <Col
                      {...responsiveDesignForList}
                      style={{ textAlign: "end" }}
                    >
                      <button
                        className="closeCustomization"
                        onClick={this.handleCancel}
                        style={{ fontWeight: "500", marginTop: "-5px" }}
                      >
                        x
                      </button>
                    </Col>
                  </Row>
                  <p
                    style={{
                      color: "rgb(81, 44, 29)",
                      float: "center",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}
                  >
                    {customProduct}
                  </p>
                </Col>
              </Row>
              <div
                style={{
                  maxHeight: `${intFrameHeightFor}px`,
                  overflowY: "scroll",
                  padding: "5px",
                }}
              >
                {customBasket.map((data) => {
                  return (
                    <Row>
                      <Col
                        {...responsiveDesignForColumn}
                        style={{ padding: "8px" }}
                      >
                        <Row>
                          <Col {...responsiveDesignCustom}>
                            <b style={{ color: "#512C1D" }}>{data.Name}</b>
                          </Col>
                          <Col
                            {...responsiveDesignText}
                            style={{ paddingLeft: "35px" }}
                          >
                            <Row>
                              <Col {...responsiveDesignText}>
                                <i
                                  onClick={() =>
                                    this.customizeToBasket(data, -1)
                                  }
                                  style={{ color: "#f35d5d" }}
                                  className="fa fa-minus-circle fa-2x"
                                  aria-hidden="true"
                                ></i>
                              </Col>

                              <Col
                                {...responsiveDesignText}
                                style={{ textAlign: "center" }}
                              >
                                <p
                                  style={{
                                    paddingTop: "5px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {data.Quantity}
                                </p>
                              </Col>
                              <Col {...responsiveDesignText}>
                                <i
                                  onClick={() =>
                                    this.customizeToBasket(data, 1)
                                  }
                                  className="fa fa-plus-circle fa-2x"
                                  style={{ color: "#f35d5d" }}
                                  aria-hidden="true"
                                ></i>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            {...responsiveDesignForItemData}
                            style={{ float: "right", marginTop: "-12px" }}
                          >
                            <Row>
                              <Col lg={24}>
                                <b style={{ float: "right", color: "#512C1D" }}>
                                  $
                                  {data.Customizable === 0 ||
                                  data.listPrice === 0.0
                                    ? parseFloat(data.Price).toFixed(2)
                                    : parseFloat(data.listPrice).toFixed(2)}
                                </b>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            {...responsiveDesignForList}
                            style={{ textAlign: "right", marginTop: "-12px" }}
                          >
                            <b style={{ color: "#512C1D" }}>
                              $ {parseFloat(data.customizeTotal).toFixed(2)}
                            </b>
                          </Col>
                          <Row>
                            <Col {...responsiveDesignForColumn}>
                              {data.selectedAddons ? (
                                <div
                                  style={{
                                    display: "flex",
                                    maxWidth: "240px",
                                    overflowX: "scroll",
                                  }}
                                >
                                  {data.selectedAddons.map((value) => {
                                    return (
                                      <Col
                                        {...responsiveDesignButtons}
                                        className={
                                          data.selectedAddons.length === 1
                                            ? "flagTure"
                                            : "flagFalse"
                                        }
                                      >
                                        <ul style={{ marginLeft: "-25px" }}>
                                          <li
                                            style={{
                                              color: "#292929",
                                              opacity: "80%",
                                            }}
                                          >
                                            {" "}
                                            {
                                              JSON.parse(value.att_val_name).cn
                                            }{" "}
                                            &ensp; &nbsp;
                                          </li>
                                        </ul>
                                      </Col>
                                    );
                                  })}
                                </div>
                              ) : (
                                <Col lg={24}></Col>
                              )}
                            </Col>
                          </Row>
                        </Row>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ display: "none" }} />
        )}
      </div>
    );
  }
}

export default Thanks;

import React from "react";
import axios from "axios";
import { Row, Col, Spin } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { returnUrl } from "../../config";
import Modal from "antd/lib/modal/Modal";

class Processing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      visibleStatus: false,
    };
  }

  componentDidMount = () => {
    const { history } = this.props;
    const dataRefUrlParam = new URLSearchParams(window.location.search).get(
      "resultIndicator"
    );
    this.setState({ loading: true, visibleStatus: true });

    const comboInfo = JSON.parse(localStorage.getItem("comboIdentifyItems"));
    const apiDetails = JSON.parse(localStorage.getItem("apiDetails"));

    const scheduleDate = JSON.parse(localStorage.getItem("scheduleDate"));
    let dateAndTime = "";
    if (scheduleDate !== null) {
      dateAndTime = scheduleDate.toString();
      let date = dateAndTime.slice(0, 10);
      let time = dateAndTime.slice(11, 16);
      dateAndTime = date + " " + "|" + " " + time;
    }
    const paymentInfo = JSON.parse(localStorage.getItem("pagerAndPickupFlags"))
      .PayType;

    let payByCredit = "";
    paymentInfo.map((data) => {
      if (data.id === 60) {
        payByCredit = parseInt(data.pay_code);
      }
    });

    const basket = JSON.parse(localStorage.getItem("basket"));
    const discountFlag = JSON.parse(localStorage.getItem("discountFlag"));

    let totalAmount = 0.0;
    if (discountFlag === false) {
      totalAmount = parseFloat(localStorage.getItem("total")).toFixed(2);
    } else {
      totalAmount = parseFloat(localStorage.getItem("amountTotal")).toFixed(2);
    }
    let discountInfos = {};
    let discountedAmount = 0.0;
    if (discountFlag === true) {
      discountedAmount = parseFloat(
        localStorage.getItem("discountedAmount")
      ).toFixed(2);
      comboInfo.map((data) => {
        if (data.activity_sn === "2003") {
          discountInfos = data;
        }
      });
    } else {
      discountedAmount = parseFloat(0).toFixed(2);
    }

    if (dataRefUrlParam !== null) {
      const pagerValue = JSON.parse(localStorage.getItem("pagerValue"));
      const saleTypeNum = parseFloat(localStorage.getItem("saleTypeNum"));

      const storeId = parseFloat(localStorage.getItem("storeId"));

      const userValues = JSON.parse(localStorage.getItem("userValues"));
      const cloud_order_number = JSON.parse(
        localStorage.getItem("cloud_order_number")
      );

      let userMailId = "";
      let userName = "";
      let userNumber = "";
      if (userValues.email === undefined) {
        userMailId = "";
        userNumber = "";
        userName = "";
      } else {
        userMailId = userValues.email;
        userNumber = userValues.number;
        userName = userValues.username;
      }

      const md5 = require("md5");
      const uri = "api/mobile/syncMobileOrder2";

      const sendingItems = [];
      const asyncBasket = [];

      basket.map((data) => {
        if (data.comboSno && data.SubProduct) {
          data.SubProduct.map((value) => {
            value.Modifiers.map((modifier) => {
              modifier.quantity = data.Quantity;
            });

            value.quantity = data.Quantity;
          });
          asyncBasket.push({
            MainProduct: `${data.comboSno}`,
            quantity: data.Quantity,
            SubProducts: {
              SubProduct: data.SubProduct,
            },
          });
        } else {
          if (data.Modifiers) {
            data.Modifiers.map((value) => {
              value.quantity = data.Quantity;
            });
            sendingItems.push({
              Modifiers: data.Modifiers,
              prodNum: `${data.Prod_IDFor}`,
              quantity: data.Quantity,
            });
          } else {
            sendingItems.push({
              Modifiers: [],
              prodNum: `${data.Prod_IDFor}`,
              quantity: data.Quantity,
            });
          }
        }
      });

      if (discountFlag === true) {
        sendingItems.push({
          couponNum: `${discountInfos.discount_sn}`,
          quantity: 1,
          discount_num: `${discountInfos.discount_num}`,
          discount_amt: `${discountedAmount}`,
          discount_name: `${discountInfos.activity_name}`,
        });
      }

      const obj_PixelPoint_OrderBefore = {
        Request: {
          Transaction: {
            ComboItems: {
              ComboItem: asyncBasket,
            },
            Payments: {
              Payment: [
                {
                  methodNumber: payByCredit,
                  tender: totalAmount,
                  status: "paid",
                  resultIndicator: `${dataRefUrlParam}`,
                },
              ],
            },
            SingleItems: {
              SingleItem: sendingItems,
            },
            label: pagerValue,
            ScheduleDate: `${scheduleDate}`,
            saleTypeNum: saleTypeNum,
            storeId: storeId,
            emailTo: `${userMailId}`,
            name: `${userName}`,
            phone: `${userNumber}`,
            SaaS_order_number: `${cloud_order_number}`,
          },
          requestType: 15,
        },
      };

      const Request_Body = JSON.stringify(obj_PixelPoint_OrderBefore);

      const now = new Date();
      const secondsSinceEpoch = Math.round(now.getTime() / 1000);

      const sign = md5(
        apiDetails.appKey +
          apiDetails.appSecret +
          uri +
          Request_Body +
          secondsSinceEpoch
      ).toString();

      const config = {
        method: "post",
        url: `${apiDetails.apiDomain}/`.concat(`${uri}`),
        headers: {
          "Content-Type": "application/json",
          "Tenant-Id": `${apiDetails.tenantID}`,
          time: `${secondsSinceEpoch}`,
          sign: `${sign}`,
          appkey: `${apiDetails.appKey}`,
          "Serial-Number": `${apiDetails.deviceID}`,
          "Sale-Channel": "APP",
          "Update-Channel": "APP",
        },
        data: Request_Body,
      };
      axios(config).then((response) => {
        const paymentStatus = response.data.result_code;
        this.setState({ loading: false });
        if (paymentStatus === 7003) {
          history.push("../errorPage/index.js");
        } else {
          localStorage.setItem(
            "successResponse",
            JSON.stringify(response.data)
          );

          history.push("../orderPlacing/index.js");
        }
      });
    } else {
      window.location.replace(`${returnUrl}`);
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    const intFrameHeight = window.innerHeight;
    const antIcon = <SyncOutlined spin />;

    return (
      <Spin
        spinning={loading}
        indicator={antIcon}
        style={{ position: "relative", top: "25em", color: "gray" }}
        size="large"
      >
        <Modal
          visible={this.state.visibleStatus}
          footer={false}
          closable={false}
        >
          <Row
            style={{
              padding: "20px",
            }}
          >
            <Col style={{ textAlign: "center" }}>
              <b style={{ fontSize: "20px" }}>PROCESSING PAYMENT...</b>
            </Col>
            <br /> <br /> <br />
            <Col style={{ textAlign: "center" }}>
              <b style={{ fontSize: "16px" }}>
                DO NOT BACKSPACE, REFRESH OR CLOSE THIS PAGE
              </b>
            </Col>
            <br /> <br />
            <Col style={{ textAlign: "center" }}>
              <b style={{ fontSize: "14px" }}>
                Please wait for the order confirmation page to appear
              </b>
            </Col>
          </Row>
        </Modal>
      </Spin>
    );
  }
}

export default Processing;

import React from "react";
import axios from "axios";
import { Row, Col } from "antd";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      getFeedback: "",
    };
  }

  componentDidMount = () => {
    this.detailsOfFeedback();
  };

  detailsOfFeedback = () => {
    const md5 = require("md5");
    const uri = "api/mobile/getFeedback";
    const apiDetails = JSON.parse(localStorage.getItem("apiDetails"));

    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    const Request_Body = JSON.stringify({ deviceNo: `${apiDetails.deviceID}` });
    const sign = md5(
      apiDetails.appKey +
        apiDetails.appSecret +
        uri +
        Request_Body +
        secondsSinceEpoch
    ).toString();

    const data = JSON.stringify({ deviceNo: `${apiDetails.deviceID}` });

    const config = {
      method: "post",
      url: `${apiDetails.apiDomain}/`.concat(`${uri}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${apiDetails.tenantID}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${apiDetails.appKey}`,
        "Serial-Number": `${apiDetails.deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        // console.log("response========>>>>>>>>>", response);
        const getFeedback = response.data.data;
        this.setState({ getFeedback: getFeedback });
      })

      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { getFeedback } = this.state;
    const responsiveDesignForColumn = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 24,
      sm: 12,
      md: 12,
    };

    const responsiveDesignDineInAndtakeAway = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 12,
      sm: 12,
      md: 12,
    };

    return (
      <div
        style={{
          heigth: "100%",
          backgroundColor: "#fff",

          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          height: "639px",
        }}
      >
        <Row
          style={{
            paddingTop: "40px",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          <Col span={24}>
            <b style={{ fontSize: "20px" }}>FEEDBACK AND CONTACT</b>
          </Col>
        </Row>
        <div style={{ overflowY: "scroll", maxHeight: "500px" }}>
          <Row style={{ padding: "40px" }}>
            <Col span={24}>
              <text style={{ fontSize: "15px", whiteSpace: "break-spaces" }}>
                {getFeedback}
              </text>
            </Col>
          </Row>
        </div>
        <br />
      </div>
    );
  }
}

export default ContactPage;

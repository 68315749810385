import React from "react";
import { Row, Col, Card } from "antd";
import errorPayment from "../../../images/errorPayment.png";
import "antd/dist/antd.css";

class ErrorInPlacing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderStatus: "",
      imageInfo: {},
    };
  }

  componentDidMount = () => {
    const orderMessages = JSON.parse(localStorage.getItem("storeMessages"));
    const imageInfo = JSON.parse(localStorage.getItem("imagesForMessages"));
    const orderStatus = orderMessages.EFT.PaymentFailed;
    this.setState({ orderStatus: orderStatus, imageInfo: imageInfo });
  };

  onGetBackToPayment = () => {
    const { history } = this.props;
    history.push("../payment/index.js");
  };

  render() {
    const { orderStatus, imageInfo } = this.state;
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };
    const intFrameHeight = window.innerHeight;

    return (
      <div
        style={{
          margin: "0px",
          height: `${intFrameHeight}px`,

          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          // height: "639px",
          backgroundColor: "#fff",
        }}
      >
        <Row style={{ paddingTop: "90px" }}>
          <Col {...responsiveDesignForColumn} style={{ textAlign: "center" }}>
            <img src={imageInfo.EFTPaymentFailed} alt="" width={200} />
          </Col>
        </Row>

        <Row>
          <Col
            {...responsiveDesignForColumn}
            style={{
              paddingTop: "25px",

              textAlign: "center",
            }}
          >
            <p
              style={{
                fontSize: "28px",

                color: "#6c757d",
              }}
            >
              Transaction Failed
            </p>
          </Col>

          <br />

          <Col
            {...responsiveDesignForColumn}
            style={{ textAlign: "center", marginTop: "-15px" }}
          >
            <p
              style={{
                fontSize: "14px",
                whitSpace: "pre-wrap",

                color: "#6c757d",
              }}
            >
              {orderStatus}
            </p>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <Row
          style={{
            margin: 0,
            position: "absolute",
            top: "80%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            textAlign: "center",
            backgroundColor: "#fff",
          }}
        >
          <Col
            {...responsiveDesignForColumn}
            style={{
              padding: "7px",
            }}
          >
            <Card
              style={{
                border: "1px solid #F35D5D",
                position: "relative",
                height: "4rem",
                width: "15rem",
              }}
              onClick={this.onGetBackToPayment}
            >
              <p
                style={{
                  color: "#F35D5D",
                  fontWeight: "700",
                  fontSize: "18px",
                  // marginTop: "-4px",
                  paddingLeft: "2px",
                  paddingTop: "5px",
                }}
              >
                Try Again
              </p>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ErrorInPlacing;
